<template>
    <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" v-vb-is:modal.show ref="scModal"
            @vb-hidden-bs-modal="isModalShown=false"
            class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content shadow-lg">
                <div class="modal-header">
                    <button v-if="!showSpinner" class="btn-close" data-bs-dismiss="modal"></button>
                    <h4 class="modal-title w-100 text-center">File Info</h4>
                </div>
                <div class="modal-body clearfix">
                    <div v-if="showSpinner" class="modal-loading text-center">
                        <ScIcon name="spinnerFW" class="text-muted"/>
                    </div>
                    <div v-if="loaded && !showSpinner" class="resource-details">
                        <div class="d-flex">
                            <img alt="Thumbnail" :src="loaded.thumb_url" class="d-block mx-auto my-2 img-thumbnail"
                              :style="{width: '200px', height: '200px'}"/>
                        </div>

                        <div class="row">
                            <div class="col-md-4"><div class="float-end text-muted">Name</div></div>
                            <div class="col-md-8">
                                <a :href="loaded.url" target="_blank" v-sc-tooltip="'Open file'">
                                <strong class="text-break">{{ loaded.name }}</strong></a>
                                <router-link v-if="canPerformListAnalytics"
                                        :to="{ name: 'reporting-analytics',
                                        params:{ workshopId: workshopId },
                                        query: { fid: loaded.id } }"
                                        target="_blank" v-sc-tooltip="'View file analytics (opens in a new tab)'">
                                    <ScIcon name="chartLineFW" class="ms-2 text-muted"/>
                                </router-link>
                                <br/>
                                <small class="text-muted" v-if="fileDeletedDate">(deleted: {{ fileDeletedDate }})</small>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4"><div class="float-end text-muted">Type</div></div>
                            <div class="col-md-8">
                                {{ loaded.content_type }}
                                <button :disabled="regenerateLoading" @click.prevent="regenerate"
                                        v-sc-tooltip="'Click to re-generate the thumbnail, alternate versions and search text.  Reload 2 minutes after clicking to view.'"
                                        class="btn btn-link btn-sm"><ScIcon name="fileCheck" class="text-muted"/></button>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4"><div class="float-end text-muted">Size</div></div>
                            <div class="col-md-8">{{ parseSize(loaded.size) }}</div>
                        </div>

                        <div v-if="loaded.width && loaded.height && loaded.width > 0" class="row">
                            <div class="col-md-4"><div class="float-end text-muted">Dimensions</div></div>
                            <div class="col-md-8">{{loaded.width}}px &times; {{loaded.height}}px</div>
                        </div>

                        <div v-if="loaded.folders && loaded.folders.length > 0" class="row">
                            <div class="col-md-4"><div class="float-end text-muted">Folder</div></div>
                            <div class="col-md-8">
                                <small v-bind:key="folder.id" v-for="folder in loaded.folders">
                                    {{ folder.name }}
                                    <template v-if="loaded.folders.indexOf(folder) + 1 < loaded.folders.length"> / </template>
                                </small>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <div class="float-end text-muted generate_now">Created</div>
                            </div>
                            <div class="col-md-8" :title="fmtDateCreated(loaded.created)">
                                {{ fmtDateNow(loaded.created) }}
                            </div>
                        </div>

                        <div v-if="loaded.uploaded_by_user_id" class="row">
                            <div class="col-md-4"><div class="float-end text-muted">By</div></div>
                            <div class="col-md-8 text-break">
                                <span v-if="loaded.uploaded_by_user_displayable_name !== loaded.uploaded_by_user_email ">
                                    {{ loaded.uploaded_by_user_displayable_name }}
                                    (<a :href="createMailto(loaded.uploaded_by_user_email)" target="_blank">{{ loaded.uploaded_by_user_email }}</a>)
                                </span>
                                <span v-else >
                                    <a :href="createMailto(loaded.uploaded_by_user_email)" target="_blank">{{ loaded.uploaded_by_user_email }}</a>
                                </span>
                            </div>
                        </div>

                        <template v-if="loaded.video_1024_size > 0 || loaded.video_2048_size > 0">
                            <br/>
                            <div class="row">
                                <div class="col-md-4 text-end text-muted">Generated</div>
                                <div class="col-md-8">
                                    <div v-if="loaded.video_1024_size > 0 ">
                                        <a :href="loaded.video_1024_url" target="_blank">SD (MP4 H264)</a>
                                        ({{ parseSize(loaded.video_1024_size) }})
                                    </div>
                                    <div v-if="loaded.video_2048_size > 0">
                                        <a :href="loaded.video_2048_url" target="_blank">HD (MP4 H264)</a>
                                        ({{ parseSize(loaded.video_2048_size) }})
                                    </div>
                                    <div v-if="loaded.video_poster_url">
                                        <a :href="loaded.video_poster_url" target="_blank">Poster</a>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-if="loaded.res_is_html_zip">
                            <br/>
                            <!-- html zip info, don't show for vge -->
                            <div  class="row">
                                <div class="col-md-4 text-end text-muted">HTML.zip</div>
                                <div class="col-md-8">
                                    File is a zipped bundle of HTML.
                                    <a href="https://github.com/ShowcaseSoftwareLtd/showcase-workshop-apis/blob/master/html-zip/README.md"
                                       target="_blank">
                                        <ScIcon name="questionCircle" v-sc-tooltip="'Developer Documentation'" /></a>
                                    <br/><ScIcon name="check"/> App based access enabled
                                    <span v-if="loaded.html_zip_browser_url">
                                        <br/><ScIcon name="check"/>&nbsp;
                                        <a :href="loaded.html_zip_browser_url" target="_blank">Browser-based access</a>
                                        enabled</span>
                                    <span v-else><br/><ScIcon name="times"/>
                                        Browser-based access disabled. To enable, please re-upload this file.</span>
                                </div>
                            </div>
                        </template>

                        <br />
                        <div class="row mb-2">
                            <div class="col-md-4 text-end text-muted">
                                <span v-sc-tooltip="'The first 10,000 characters are indexed for search.'">Text for search</span>
                                <template v-if="canPerformListPresForView">
                                    <br/><a href="#" @click.prevent="startEditContent" v-sc-tooltip="'Edit search content'">
                                    <ScIcon name="editFW" class="text-muted"/></a>
                                </template>
                            </div>
                            <div class="col-md-8 p-0 pe-1 pt-1">
                                <div v-if="!searchContentEditing" class="p-1 border rounded" style="max-height: 75px; min-height: 45px; overflow-y: auto;">{{ searchContent }}</div>
                                <form v-else >
                                    <textarea v-model="searchContent" ref="searchContentTextarea"
                                              class="w-100 form-control mb-2" style="height: 75px;" :disabled="searchContentLoading"></textarea>
                                    <div class="alert alert-danger" v-if="searchContentTooLong">
                                        10,000 character limit. You have {{ searchContent.length }}.
                                    </div>
                                    <button type="button" class="btn btn-primary-orange btn-sm fw-bold" @click.prevent="editSearchContent"
                                            :disabled="searchContentLoading">Save</button>
                                    <ScIcon name="questionCircleFW" class="text-muted ms-2 align-middle"
                                       v-sc-tooltip="'Presentations using this file need to be republished for changes here to be searchable'"/>
                                    <button type="button" class="btn btn-link btn-sm float-end px-1"
                                            @click.prevent="cancelEditContent" :disabled="searchContentLoading">Cancel</button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>


<script>

    import $ from 'jquery';
    import {format, formatDistanceToNow} from 'date-fns';
    import ScCommonUtil from '../../shared/common/ScCommonUtil';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';


    let DataManager = {

        loadData (vm) {
            vm.showSpinner = true;
            vm.loaded = null;

            $.ajax({
                url: '/main/resources/ajax_get_resource',
                data: {
                    resource_id: vm.resourceId,
                    workspace_id: vm.workshopId
                }
            }).done(function(data) {
                vm.loaded = data;
                vm.searchContent = data.search_content;

            }).fail(function(jqXhr) {
                ScNotification.growlXhrError(jqXhr, 'fetching resource');

            }).always(function() {
                vm.showSpinner = false;
            });
        },
        generateNow (vm) {
            vm.regenerateLoading = true;
            $.ajax({
                method: 'POST', url: '/main/resources/ajax_generate_alternates',
                data: {
                    resource_id: vm.resourceId,
                    workspace_id: vm.workshopId
                }
            }).done(function() {
                ScNotification.growlSuccessMsg('Thumb and alternate version generation process started.  ' +
                    'Reload in 2 minutes to verify.');
            }).fail(function(jqXhr) {
                ScNotification.growlXhrError(jqXhr, 'triggering regeneration');
            });
        },
        updateSearchContent (vm) {
            vm.searchContentLoading = true;
            $.ajax({
                method: 'POST', url: '/main/resources/ajax_update_search_content_for_res',
                data: {
                    search_content: vm.searchContent,
                    resource_id: vm.resourceId,
                    workspace_id: vm.workshopId
                }
            }).done(function(data) {
                if (data.status === 'ok') {
                    vm.loaded.search_content = data.search_content;
                    vm.searchContent = data.search_content;
                    vm.searchContentEditing = false;

                    vm.enablePublishBtn(data.affected_pres);
                } else
                    ScNotification.growlErrMsg(data.err_msg);

            }).fail(function(jqXhr) {
                ScNotification.growlXhrError(jqXhr, 'updating searchable text');
            }).always(function() {
                vm.searchContentLoading = false;
            });

        }

    };


    let _ins = null;

    export default {
        name: "ScResourceInfoModal",
        components: {ScIcon},
        data () {
            return {
                isModalShown: false,
                workshopId: null,
                resourceId: null,
                loaded: null,
                showSpinner: false,
                regenerateLoading: false,
                canPerformListAnalytics: false,  // defined locally to avoid circular dependency on MainAppMixin
                canPerformListPresForView: false,

                searchContentEditing: false,
                searchContent: null,
                searchContentLoading: false
            };
        },
        mounted () {
            _ins = this;
        },
        beforeUnmount() {
            _ins = null;
        },
        methods: {
            parseSize (size) {
                return ScCommonUtil.filesizeWithBytes(size);
            },
            createMailto (email) {
                return 'mailto:' + email;
            },
            fmtDateCreated (time) {
                return format(new Date(time), 'EEEE, LLLL dd, yyyy h:mm a');
            },
            fmtDateNow (time) {
                return formatDistanceToNow(new Date(time), { addSuffix: true });
            },
            regenerate () {
                DataManager.generateNow(this);
            },
            editSearchContent () {
                if (this.searchContent && this.searchContent.length > 10000) {
                    ScNotification.growlErrMsg('Only 10,000 characters allowed for "Text for search"');
                    return;
                }
                if (this.searchContent !== this.loaded.search_content) {
                    DataManager.updateSearchContent(this);
                }   else {
                    this.searchContentEditing = false;
                }
            },
            cancelEditContent () {
                this.searchContentEditing = false;
                this.searchContent = this.loaded.search_content;
            },

            enablePublishBtn (affected_pres) {
                if (this.$store.state.pres && affected_pres.includes(this.$store.state.pres.showcase.presentation.id))
                    this.$store.dispatch('presDraftCompileStarted');

                if (this.$store.state.pres && this.$store.state.plist){
                    for (let presId of affected_pres) {
                        for (let p of this.$store.state.plist.presentations) {
                            if (p['id'] === presId) this.$store.commit('plistPresHasChanges', presId);

                        }
                    }
                }
            },
            startEditContent () {
                this.searchContentEditing = true;
                this.$nextTick(() => {
                    this.$refs.searchContentTextarea.focus();
                });

            },
            toggleBsModal(options) {
                //console.log('toggleBsModal', this.$refs, _ins.$el);
                this.resourceId = options.resourceId;
                this.workshopId = options.workshopId;
                this.canPerformListAnalytics = options.canPerformListAnalytics;
                this.canPerformListPresForView = options.canPerformListPresForView;
                this.regenerateLoading = false;
                this.isModalShown = true;
                DataManager.loadData(this);
            }
        },

        computed: {
            searchContentTooLong () {
                return (this.searchContent && this.searchContent.length > 10000);
            },
            fileDeletedDate () {
                return (this.loaded.deleted_date) ? format(new Date(this.loaded.deleted_date), 'dd MMM yyyy h:mm a') : '';
            }
        },


        //
        // public functions
        //

        modal (options) {
            _ins.toggleBsModal(options);
        }

    }

</script>

