<template>
  <div class="no-auto-container" :data-sc-lang="mainMxScLang">
    <div class="container-fluid no-auth-masthead text-white d-flex align-items-center justify-content-center">
      <div class="row">
        <div class="signin-overlay prevent-drag prevent-select w-100">
          <div class="d-flex justify-content-center p-4 mb-0 no-auth-logo-wrap">
            <img :src="mainMxThemedAssetUrl('/signin-logo-v4.png')" style="width: 250px; height: 250px;"
                 alt="Logo" class="splash-logo my-1" />
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid no-auth-main text-white d-flex justify-content-center pb-2">
      <div class="row">
        <div class="w-100 prevent-drag prevent-select">
          <div class="ms-auto me-auto sc-signin-form-controls px-2 px-md-0" style="width: 320px;">

            <div v-if="screenTooSmall" class="alert alert-info mt-2">
              <strong>Heads up!</strong> This website is designed to be used on a bigger screen; you may experience
              some odd behavior.
            </div>

            <slot></slot>

            <div style="opacity: 0.2;" class="version-footer text-center mt-4">
              <span class="constants-app-display-name">{{appDisplayName}}</span>&nbsp;
              <span class="constants-app-version">{{appVersion}}</span>
            </div>
          </div>
        </div>
        <ScHelpWidget />
      </div>
    </div>
  </div>
</template>



<script>

    import MainAppMixin from '../MainAppMixin'
    import ScHelpWidget from '../global/ScHelpWidget.vue'

    export default {
      name: 'ScLayoutNoAuth',
      mixins: [MainAppMixin],
      components: {ScHelpWidget},
      data () {
        return {
          screenTooSmall: false
        }
      },
      mounted () {
        this.calcScreenTooSmall();
        window.addEventListener('resize', this.calcScreenTooSmall, false);
      },
      beforeUnmount () {
        window.removeEventListener('resize', this.calcScreenTooSmall, false);
      },
      methods: {
        calcScreenTooSmall () {
          this.screenTooSmall = document.documentElement.clientWidth < 992
              || document.documentElement.clientHeight < 400;
        }
      },
      computed: {
        appVersion () {
          return window.SC_VERSION ? window.SC_VERSION : '0.0.0';
        },
        appDisplayName () {
          return 'Showcase Website';
        },
      }
    }

</script>


<style>

  .no-auto-container {
    min-height: 100vh;
    background: #008ab0 linear-gradient(to right, #008ab0 0%, #26bcd6 100%);
  }

  .no-auth-main a, .no-auth-main a:hover {
    color: white !important;
  }
  .no-auth-main .alert-danger a, .no-auth-main .alert-danger a:hover {
    color: black !important;
  }

</style>

