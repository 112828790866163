<template>
    <div id="app">

        <div v-if="!cookieCheckPassed">
            <div class="text-center" style="margin-top: 20vh;margin-bottom: 20vh;">
                <h2>Cookies disabled.</h2>
                <div>Showcase uses cookies to store authorisation.  Please enable cookies and reload the page to continue.</div>
            </div>
        </div>

        <div v-else-if="authLoading">
            <div class="text-center" style="margin-top: 20vh; margin-bottom: 20vh;">
                <ScIcon name="spinnerMedium" class="text-muted"/>
            </div>
        </div>

        <div v-else-if="$route.meta.needsAuth && !userPrefs.prefsLoaded">
            Error loading user preferences.  Please click reload in your browsers toolbar.
        </div>

        <LoginForm v-else-if="$route.meta.needsAuth && !userPrefs.user_id && userPrefs.prefsLoaded"
            @successful-login="loadUserPrefs()">
        </LoginForm>

        <AcceptTermsForm v-else-if="$route.meta.needsAuth && userPrefs.user_id && userPrefs.prefsLoaded
                && !userPrefs.termsAccepted && userPrefs.currentWorkshopId">
        </AcceptTermsForm>

        <div v-else-if="$route.meta.needsAuth && $route.meta.neededWsAction && userPrefs.user_id
                   && userPrefs.prefsLoaded
                   && !$store.getters.userCanPerform($route.meta.neededWsAction, Number($route.params.workshopId))">
            <div class="text-center" style="margin-top: 20vh;margin-bottom: 20vh;">
                <h2>Unauthorised.</h2>
                <div>Proceed to
                    <router-link :to="{name: 'workshop-default'}">Present</router-link></div>
            </div>
        </div>

        <ScNotFound v-else-if="$route.name === 'notfound'"/>

        <div v-else-if="$route.meta.needsAuth && $route.meta.neededWsAction && $route.params.workshopId
                   && Number($route.params.workshopId) !== userPrefs.currentWorkshopId">
            <div class="text-center" style="margin-top: 20vh;margin-bottom: 20vh;">
                <h2>Workspace ID Mismatch</h2>
                <div>Proceed to
                    <router-link :to="{name: 'workshop-default'}">Home</router-link></div>
            </div>
        </div>

        <router-view v-else :key="$route.meta.scRemountKey ? $route.meta.scRemountKey : $route.fullPath"
                     v-slot="{ Component }">
            <transition name="sc-transition-fade" mode="out-in" appear>
                <component :is="Component" />
            </transition>
        </router-view>

        <ScNotification></ScNotification>
        <ScRenameModal2></ScRenameModal2>
        <ScConfirmModal2></ScConfirmModal2>
        <ScResourceInfoModal></ScResourceInfoModal>

        <ScOperationsV2 v-if="userPrefs && userPrefs.user_id && userPrefs.pubSubKey && userPrefs.pubSubDomain
                                && currentWorkspaceObj && currentWorkspaceObj.pubnub_workshop_channel_key
                                && $route && ['workshop-home', 'editor', 'editor-preview', 'library'].indexOf($route.name) !== -1"
                        :subKey="userPrefs.pubSubKey" :subDomain="userPrefs.pubSubDomain"
                        :userId="userPrefs.user_id"
                        :channelKey="currentWorkspaceObj.pubnub_workshop_channel_key"></ScOperationsV2>
    </div>
</template>

<script>

/* important that css is imported first before anything else so all components can override styles */
import 'flatpickr/dist/flatpickr.min.css'; // add base css from flatpickr
import "./around2/theme.scss";
import * as Sentry from '@sentry/browser';


import store from './store';
import WebappUserPrefsStore from './store/WebappUserPrefsStore';
import ScOperationsV2 from './components/global/ScOperationsV2.vue';
import ScBootstrapUtils from './components/global/ScBootstrapUtils';
import LoginForm from './components/account/LoginForm.vue';
import ScRenameModal2 from './shared/common/ScRenameModal2.vue';
import ScConfirmModal2 from './shared/common/ScConfirmModal2.vue';
import ScIcon from './shared/common/ScIcon.vue';
import AcceptTermsForm from "./components/account/AcceptTermsForm.vue";
import ScNotFound from "./components/global/ScNotFound.vue";
import ScNotification from "./shared/common/ScNotification.vue";
import ScResourceInfoModal from "./components/global/ScResourceInfoModal.vue";

if (!store.state.user) store.registerModule('user', WebappUserPrefsStore);



store.watch(store.getters.userGetUserId, (userId) => {
  //console.log('App.vue watching user id', userId);
  if (!userId) {
    setTimeout(() => {
      //console.error('needs to login???');
      ScBootstrapUtils.modalHideAll();
    }, 500);  // give any pending modal creations enough time to popup
  }
});



export default {
  components: { AcceptTermsForm, LoginForm, ScRenameModal2, ScConfirmModal2, ScIcon, ScNotFound, ScNotification,
    ScResourceInfoModal, ScOperationsV2 },
  data: function() {
    return {
      authLoading: true,
      cookieCheckPassed: true
    }
  },
  mounted: function() {
    //console.log('App mounted', this.$route.name, this.userPrefs.currentWorkshopId);
    if (document.location && document.location.hostname === 'showcase.vodafone.com') {
      window.location.href = 'https://showcaseworkshop.com/vodafone/domain/';
    }
    if (typeof navigator.cookieEnabled === 'boolean') {
      this.cookieCheckPassed = navigator.cookieEnabled;
    }
    if (this.$route.name) this.routeLoaded();  // soft-reload (watch $route not fired)
  },
  methods: {
    routeLoaded: function() {
      //console.log('routeLoaded', this.$route);
      if (this.$route.meta.needsAuth) {
        if (this.userPrefs.prefsLoaded) {
          this.updateWorkshopId();
        }   else {
          this.loadUserPrefs();
        }
      }   else {
        this.authLoading = false;
      }
    },
    loadUserPrefs: function() {
      //console.log('App loadUserPrefs');
      this.$store.dispatch('userPrefsLoad').then(() => {
        //console.log('App loadUserPrefs ok');
        this.updateWorkshopId();
      });
    },
    updateWorkshopId: function() {
      //console.log('updateWorkshopId', this.userPrefs, this.userPrefs.termsAccepted);
      if (this.userPrefs && this.userPrefs.user_id) {
        if (this.userPrefs.currentWorkshopId !== Number(this.$route.params.workshopId)) {
          this.$store.commit('userWorkshopChange', Number(this.$route.params.workshopId));
        }
        this.handleRedirects();
      }
      this.authLoading = false;
    },
    handleRedirects: function() {
      //console.log('App handleRedirects', this.$route.name, this.$route.params.workshopId, this.userPrefs.currentWorkshopId);
      if (this.$route.name === 'workshop-default') {
        this.$store.commit('userWorkshopChange', null);  // set the default workshop
        if (this.userPrefs.currentWorkshopId) {
          if (this.$store.getters.userCanPerform('a:list_pres_for_edit', this.userPrefs.currentWorkshopId)) {
            this.$router.push({
              name: 'workshop-home', params: {workshopId: this.userPrefs.currentWorkshopId}});
            return;
          } else {
            this.$router.push({
              name: 'viewer-list', params: {workshopId: this.userPrefs.currentWorkshopId}});
            return;
          }
        }
      }

      if (this.$route && this.$route.params && this.$route.params.workshopId) {
        if (this.userPrefs.currentWorkshopId !== Number(this.$route.params.workshopId)) {
          // if don't have that workshop
          if (Object.keys(this.$route.params).length === 1) {
            // if workshop is the only param we are safe to stick to the same route
            this.$router.push({
              name: this.$route.name, params: {workshopId: this.userPrefs.currentWorkshopId}});
            return;
          }   else {  // otherwise take them home
            this.$router.push({
              name: 'workshop-default'});
            return;
          }
        }
      }
      if (this.$route.name === 'workshop-home'
        && !this.$store.getters.userCanPerform('a:list_pres_for_edit', this.userPrefs.currentWorkshopId)
        && this.$store.getters.userCanPerform('a:list_pres_for_view', this.userPrefs.currentWorkshopId)) {
        this.$router.push({
          name: 'viewer-list', params: {workshopId: this.userPrefs.currentWorkshopId}});
      }
    },
  },
  computed: {
    userPrefs() {
      return this.$store.state.user;
    },
    currentWorkspaceObj() {
      return this.$store.getters.userCurrentWorkspaceObj;
    }
  },

  watch: {
    '$route': function() {
      //console.log('App watch $route', this.$route.name);
      this.routeLoaded();
    }
  }

};


/* bug tracking */
Sentry.init({
  dsn: 'https://62fdfb0f7ad34e14aa54fd99b1f41d22@sentry.io/226200',
  release: window.SC_VERSION, environment: window.SC_ENV,
  beforeSend(event, hint) {
    if (window.SC_DEV_ENV) {
      // eslint-disable-next-line
      let ex = event;
      if (hint && hint.originalException) ex = hint.originalException;
      else if (event && event.exception && event.exception.values && event.exception.values[0]) ex = event.exception.values[0];
      console.error('Sending error to sentry (msg not shown in prod)');
      console.error(ex);
    }
    return event;
  },
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'Unable to preventDefault inside passive event listener due to target being treated as passive'],
  autoSessionTracking: false
});

</script>

<style>

    /* import here so that it overrides bootstrap */
    @import url(./shared/common/sc-modals.css);
    @import url(./shared/common/sc-transitions.css);

</style>
