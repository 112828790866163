<template>
    <div class="text-center" style="margin-top: 20vh;margin-bottom: 20vh;">
        <h2>Not found</h2>
        <div>Proceed to
            <router-link :to="{name: 'workshop-default'}">Home</router-link></div>
    </div>
</template>

<script>


export default {
    name: 'ScNotFound',
}
</script>