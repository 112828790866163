<template>
    <ScLayoutNoAuth>
        <form v-if="formMode === 'validate_email'" class="form sc-signin-form mt-4" @submit.prevent="validateEmailClick()">
            <div class="mb-3 form-floating">
                <input class="form-control auth_email w-100" id="auth_user_email2" name="email" type="email"
                       placeholder="Email" tabindex="1" v-model="email" autofocus
                       :disabled="validateEmailLoading" autocomplete="email"
                       @keyup.enter.prevent="validateEmailClick()"/>
                <label for="auth_user_email2" class="text-body">Email</label>
            </div>

            <input type="hidden" v-model="pwd" name="password" />

            <div class="controls actions clearfix text-center">
                <button class="btn col-sm-8 col-sm-offset-2 btn-no-auth-submit p-2 fw-bold"
                        :disabled="validateEmailLoading"
                        @click.prevent="validateEmailClick()" tabindex="4">
                    <span v-if="!validateEmailLoading">Log In</span>
                    <ScIcon v-else name="spinnerFW" class="ms-2"/>
                </button>

                <!-- beta links to use oauth directly -->
                <a href="/main/default_oauth2/logout_redirect"
                   class="m-0 p-0" style="color: transparent !important;">.</a>
                <a href="/main/default_oauth2/login_redirect?identity_provider=Microsoft&client_name=browser"
                   class="m-0 p-0" style="color: transparent !important;">.</a>
                <a href="/main/default_oauth2/login_redirect?identity_provider=Google&client_name=browser"
                   class="m-0 p-0" style="color:transparent !important;">.</a>
                <!-- / beta links to use oauth directly -->

                <br/>&nbsp;<!-- take up space for back link on next step -->
            </div>

        </form>
        <form v-if="formMode === 'validate_pwd'" class="form sc-signin-form mt-1" @submit.prevent="validatePwdClick()">
            <div class="mb-2 form-floating">
                 <input class="form-control auth_email w-100" id="auth_user_email3" name="email" type="email"
                           placeholder="Email" tabindex="1" v-model="email"
                           :disabled="true" autocomplete="email"/>
                 <label for="auth_user_email3" class="text-body">Email</label>
            </div>

            <div v-if="optionalAzureLoginUrl" class="mb-3">
                <div class="controls text-center mt-4 my-2">
                    <a :href="optionalAzureLoginUrl">
                        <!-- https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-add-branding-in-azure-ad-apps -->
                        <img :src="mainMxCdn('/showcase-icons/7.1.1/ms-symbollockup_signin_light.svg')"
                             style="width: 214px; height: 41px;" alt="Sign in with Microsoft" />
                    </a>
                </div>
                <div class="controls text-center mt-3">
                    or enter password:
                </div>
            </div>

            <div class="mb-3 form-floating">
                 <input class="password form-control auth_pwd w-100" id="auth_user_password2" name="pwd" type="password"
                           ref="signinPwd" autocomplete="current-password" autofocus
                           :disabled="validatePwdLoading"
                           placeholder="Password" tabindex="2" v-model="pwd"
                           @keydown="detectCapsLock($event)"
                           @keyup.enter.prevent="validatePwdClick()" />
                 <label for="auth_user_password2" class="text-body">Password</label>
                 <div v-if="capsLockEnabled" class="float-start my-2">
                        <ScIcon name="exclamationTriangleFW"/> Caps lock is on</div>
            </div>

            <div class="controls actions clearfix text-center">
                <button class="btn col-sm-8 col-sm-offset-2 btn-no-auth-submit p-2 fw-bold"
                        :disabled="validatePwdLoading"
                        @click.prevent="validatePwdClick()" tabindex="4">
                    <span v-if="!validatePwdLoading">Log in</span>
                    <ScIcon v-else name="spinnerFW" class="ms-2"/>
                </button>
                <br/><a href="#" @click.prevent="formMode='validate_email'" class="mt-3 btn btn-link">
                    <ScIcon name="chevronLeftFW"/> Back</a>

            </div>
        </form>

        <form v-if="formMode === 'validate_token'" class="form sc-signin-form mt-1" @submit.prevent="validatePwdClick()">
            <div class="controls actions clearfix text-center">
                <button class="btn col-sm-8 col-sm-offset-2 btn-no-auth-submit p-2 fw-bold" :disabled="true"
                        v-if="validateAuthTokenLoading"><ScIcon name="spinnerFW" class="ms-2" /></button>
                <div v-if="!validateAuthTokenLoading && authTokenError" class="alert alert-danger">
                    {{authTokenError}}
                </div>

                <br/><a href="#" @click.prevent="formMode='validate_email'" class="mt-2 btn btn-link">
                <ScIcon name="chevronLeftFW"/> Back</a>
            </div>
        </form>

        <div v-if="['validate_email', 'validate_pwd'].includes(formMode)" class="mt-4 text-center">
            <router-link class="btn btn-link px-0 fw-bold"
                         :to="{name: 'reset_v2'}">Have you forgotten your password?</router-link>
        </div>
        <div v-if="['validate_email', 'validate_pwd'].includes(formMode)" class="mt-2 text-center signup-link">
            <router-link class="btn btn-link fw-bold"
                         :to="{name: 'signup'}">Don't have a Showcase account?</router-link>
        </div>
    </ScLayoutNoAuth>
</template>


<script>

    import MainAppMixin from '../MainAppMixin';
    import ScLayoutNoAuth from '../global/ScLayoutNoAuth.vue';
    import ScVueTrackEvents from '../global/ScVueTrackEvents';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScCommonUtil from '../../shared/common/ScCommonUtil';
    import ScIcon from '../../shared/common/ScIcon.vue';

    export default {
        name: 'LoginForm',
        emits: ['successful-login'],
        mixins: [MainAppMixin],
        components: {ScLayoutNoAuth, ScIcon},

        data: function() {
            return {
                lastAdvancedModeClickTime: 0,
                advancedModeClickCount: 0,
                email: null,
                pwd: null,
                loginLoading: false,
                validateEmailLoading: false,
                validatePwdLoading: false,
                validateAuthTokenLoading: false,
                authTokenError: null,
                capsLockEnabled: false,
                optionalAzureLoginUrl: null,
                oauthStateKey: null,
                formMode: 'validate_email',  // validate_email, validate_pwd, validate_token
            }
        },
        mounted() {
            let lsEmail = null;
            try {
                if (window.localStorage) lsEmail = window.localStorage.getItem('scLastEmail');
            } catch(e) {
                // ignore
            }
            if (lsEmail) this.email = lsEmail;

            this.oauthStateKey = this.$route.query.osk;
            if (this.oauthStateKey && this.oauthStateKey.length > 0) {
                this.formMode = 'validate_token';
                this.validateAuthTokenClick();
            }
        },
        methods: {
            detectCapsLock: function(event ) {
                let caps = event.getModifierState && event.getModifierState( 'CapsLock' );
                this.capsLockEnabled = !!caps;
            },
            validateEmailClick: function() {
                ScNotification.clear();
                if (!ScCommonUtil.validateEmail(this.email)) {
                    ScNotification.growlErrMsg('Please enter a valid email address');
                } else {
                    this.validateEmailLoading = true;
                    this.optionalAzureLoginUrl = null;
                    this.$store.dispatch('userLoginValidateEmail', {
                        email: this.email, sendCode: false
                    }).then((res) => {
                        this.validateEmailLoading = false;
                        if (res && res.invite_pending) {
                            ScNotification.growlErrMsg('You have a pending invite! Please check your email for a verification code.');
                        } else if (res && !res.user_exists) {
                            ScNotification.growlErrMsg('We can\'t find a Showcase account for that email. To sign up, click "Don\'t have a Showcase account?" below.');
                        } else if (res && res.login_requirements_type === 'oauth_force_v1') {
                            this.validateEmailLoading = true;
                            document.location = res.login_requirements_url
                        }   else if (res && res.login_requirements_type === 'oauth_optional_v1') {
                            this.formMode = 'validate_pwd';
                            this.optionalAzureLoginUrl = res.login_requirements_url;
                        }   else {  // login_requirements_type = standard_v1
                            this.formMode = 'validate_pwd';
                        }
                        setTimeout(() => {  // reset in case of failure
                            this.validateEmailLoading = false;
                        }, 2000);
                    }).catch(() => {
                        this.validateEmailLoading = false;
                    });
                }
            },
            validatePwdClick: function() {
                ScNotification.clear();
                if (!ScCommonUtil.validateEmail(this.email)) {
                    ScNotification.growlErrMsg('Please enter a valid email address');
                } else if (!this.pwd) {
                    ScNotification.growlErrMsg('Please enter a password')
                } else {
                    this.validatePwdLoading = true;
                    this.$store.dispatch('userLoginPost', {
                        email: this.email, pwd: this.pwd
                    }).then(() => {
                        ScVueTrackEvents.trackLoginSuccess();  // let public website analytics know this is a customer
                        this.$emit('successful-login');  // logic handled in app.vue
                        setTimeout(() => {  // reset in case of failure
                            this.validatePwdLoading = false;
                        }, 2000);
                    }).catch(() => {
                        this.validatePwdLoading = false;
                    });
                }
            },
            validateAuthTokenClick: function() {
                ScNotification.clear();
                this.validateAuthTokenLoading = true;
                this.authTokenError = null;
                this.$store.dispatch('userValidateAuthToken', {
                    osk: this.oauthStateKey
                }).then(() => {
                    ScVueTrackEvents.trackLoginSuccess();  // let public website analytics know this is a customer
                    this.$emit('successful-login');  // logic handled in app.vue
                    setTimeout(() => {  // reset in case of failure
                        this.validateAuthTokenLoading = false;
                    }, 2000);
                }).catch((msg) => {
                    this.authTokenError = msg;
                    this.validateAuthTokenLoading = false;
                });
            }
        },
        computed: {
            //
        },
        watch: {
            email() {
                try {
                    if (window.localStorage) window.localStorage.setItem('scLastEmail', this.email);
                } catch(e) {
                    // ignore
                }
            }
        }

    }

</script>

