<template>

   <div v-if="checklistLoading" class="container mt-3">
    <div class="row g-0 justify-content-center">
      <div class="col-10 my-3">
        <ScIcon name="spinnerFW" class="text-muted"/>
      </div>
    </div>
   </div>

  <div v-if="!checklistLoading" class="container mt-3">
    <div class="row g-0 justify-content-center">
      <div class="col-10 my-3">
        <div class="progress border border-dark" style="height: 40px">
          <div v-if="calculatedProgressInt > 0"
               class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar"
               :style="progressStyleObj" :aria-valuenow="calculatedProgressInt"
               aria-valuemin="0" aria-valuemax="100"><h6 class="mb-0 text-white">{{calculatedProgressText}}</h6></div>
          <div v-if="calculatedProgressRemainingInt"
               class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
               :style="progressRemainingStyleObj" :aria-valuenow="calculatedProgressRemainingInt"
               aria-valuemin="0" aria-valuemax="100"><h6 v-if="calculatedProgressInt === 0" class="mb-0 text-white">0%</h6></div>
        </div>
      </div>
    </div>

    <div class="row g-0 justify-content-center">
      <div class="col-10 my-3">
        <template v-if="isChecklistComplete">
          <h1>Winner!</h1>
          <h5 class="fw-normal"><ScIcon name="sparklesFW" class="me-1"/>
            You've made a <template v-if="partialSuccess">superb</template><template v-else>textbook</template> start on your Showcase success. Nice one!</h5>
        </template>
        <template v-else-if="isTrialExpired">
          <h3>Oops! Your trial's expired.</h3>
          <p>Great to have you here.</p>
        </template>
        <template v-else >
          <h3>Welcome!</h3>
          <p>Great to have you here.</p>
        </template>


        <p><ScIcon name="eyesFW" class="me-1"/> Showcase superpowers your sales presentations into slick, enriching experiences for your prospects and team.</p>
        <p><ScIcon name="trophyFW" class="me-1"/> Your brand looks good; info is accurate and trackable; everyone wins!</p>
        <p v-if="!isChecklistComplete"><ScIcon name="rocketFW" class="me-1"/> Start now on your 7 speedy steps to Showcase Success:</p>
        <p v-if="partialSuccess"><ScIcon name="rocketFW" class="me-1"/> There's more to explore. You can still try these steps:</p>

        <div class="row">
          <div class="col">

            <div class="my-1 d-flex">
              <span><ScIcon name="checkFas" class="text-success h4 mb-0" /></span>
              <span class="ms-2 my-auto">Sign up for a trial - nailed it</span>
            </div>

            <div class="my-1 d-flex">
              <span v-if="stepCreatePresentation"><ScIcon name="checkFas" class="text-success h4 mb-0" /></span>
              <span v-else ><ScIcon name="squareFW" /></span>
              <span class="ms-2 my-auto">
                Make a <a href="https://support.showcaseworkshop.com/help/create-a-new-showcase"
                          target="_blank">new presentation</a></span>
            </div>

            <div class="my-1 d-flex">
              <span v-if="stepUploadFile"><ScIcon name="checkFas" class="text-success h4 mb-0" /></span>
              <span v-else ><ScIcon name="squareFW" /></span>
              <span class="ms-2 my-auto"> Upload a
                <a href="https://support.showcaseworkshop.com/help/upload-files"
                   target="_blank">file</a>, and
                <a href="https://support.showcaseworkshop.com/help/add-content-to-a-hotspot"
                   target="_blank">add it to a hotspot</a></span>
            </div>

            <div class="my-1 d-flex">
              <span v-if="stepPublishPresentation"><ScIcon name="checkFas" class="text-success h4 mb-0" /></span>
              <span v-else ><ScIcon name="squareFW" /></span>
              <span class="ms-2 my-auto"> Publish your
                <a href="https://support.showcaseworkshop.com/help/publishing-a-showcase"
                   target="_blank">presentation</a></span>
            </div>

            <div class="my-1 d-flex">
              <span v-if="stepAddUser"><ScIcon name="checkFas" class="text-success h4 mb-0" /></span>
              <span v-else ><ScIcon name="squareFW" /></span>
              <span class="ms-2 my-auto"> <a href="https://support.showcaseworkshop.com/help/adding-a-user"
                   target="_blank">Add users</a> to your workshop</span>
            </div>

            <div class="my-1 d-flex">
              <span v-if="stepShareContent" ><ScIcon name="checkFas" class="text-success h4 mb-0" /></span>
              <span v-else style="width: 26px;"><ScIcon name="squareFW" /></span>
              <span class="ms-2 my-auto"> Share your
                <a href="https://support.showcaseworkshop.com/help/share-content-via-email"
                   target="_blank">presentation</a> (and
                <a href="https://support.showcaseworkshop.com/help/sharing-history"
                   target="_blank">track the results</a>)</span>
            </div>

            <div class="my-1 d-flex">
              <span v-if="stepSubscribe"><ScIcon name="checkFas" class="text-success h4 mb-0" /></span>
              <span v-else ><ScIcon name="squareFW" /></span>
              <span class="ms-2 my-auto">
                <template v-if="isTrialExpired">
                  <strong class="text-danger">Your trial's expired</strong> - it's a good time to
                  <router-link :to="{ name: 'settings-billing', params: { workshopId: mainMxCurrentWorkshopId }}">
                  subscribe!</router-link></template>
                <template v-else >
                  <router-link :to="{ name: 'settings-billing', params: { workshopId: mainMxCurrentWorkshopId }}">
                  Subscribe!</router-link>
              </template>
              </span>
            </div>

          </div>

          <div v-if="isChecklistComplete" class="col">
            <div style="width: 245px; overflow: hidden;">
             <video loop muted autoplay style="height: 100%; margin-left: -5px;">
               <source type="video/mp4" src="https://cdn.showcaseworkshop.com/showcase-icons/7.1.1/celebrate.mp4" />
             </video>
            </div>
          </div>
        </div>

        <div class="my-4 text-center">
          <p v-if="isChecklistComplete">Where to from here? Want advanced tips?</p>
          <p v-else >No time? Need more details?</p>
          <a href="https://calendly.com/showcase-workshop/30-demonstration" target="_blank"
             class="btn btn-primary-green fw-bold">Book a free video call demo</a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

  import $ from 'jquery';
  import MainAppMixin from '../MainAppMixin';
  import ScIcon from '../../shared/common/ScIcon.vue';
  import ScNotification from '../../shared/common/ScNotification.vue';

  export default {
    name: "OnboardingChecklist",
    mixins: [MainAppMixin],
    components: {ScIcon},
    data () {
        return {
          checklistLoading: false,
          checklistSteps: null,
          stepCreatePresentation: false,
          stepUploadFile: false,
          stepPublishPresentation: false,
          stepAddUser: false,
          stepShareContent: false,
          stepSubscribe: false,
        }
    },
    mounted () {
      this.loadChecklist()
    },
    methods: {
      loadChecklist () {
        this.checklistLoading = true;
        $.ajax({
          type: 'GET', url: '/main/guide/get_onboarding_list',
          data: { workspace_id: this.mainMxCurrentWorkshopId },

          }).done((data) => {
            if (data.status !== 'ok') return;
            //console.log(data)
            this.checklistSteps = data.onboarding_checklist;
            this.stepCreatePresentation = data.onboarding_checklist.step_create_presentation;
            this.stepUploadFile = data.onboarding_checklist.step_upload_file;
            this.stepPublishPresentation = data.onboarding_checklist.step_publish_presentation;
            this.stepAddUser = data.onboarding_checklist.step_add_user;
            this.stepShareContent = data.onboarding_checklist.step_share_content;
            this.stepSubscribe = data.onboarding_checklist.step_subscribe;

          }).always(() => {
            this.checklistLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading onboarding checklist');
          });
      }
    },
    computed: {
      calculatedProgressInt () {
        let checklistItems = 1;
        if (this.checklistSteps)
          Object.values(this.checklistSteps).forEach((step) => {
            if (step) checklistItems++;

          });
        return Math.round(100/7 * checklistItems);
      },
      calculatedProgressRemainingInt () {
        return 100 - this.calculatedProgressInt;
      },
      calculatedProgressText () {
        return `${this.calculatedProgressInt}%`;
      },
      progressStyleObj () {
        return { width: this.calculatedProgressText }
      },
      progressRemainingStyleObj () {
        return { width: this.calculatedProgressRemainingInt + '%', backgroundColor: '#dfdfeb' }
      },
      isChecklistComplete () { // show success state once subscribed
        return this.calculatedProgressInt === 100 || this.stepSubscribe;
      },
      partialSuccess () { // different wording for partial completion
        return this.calculatedProgressInt < 100 && this.stepSubscribe;
      },
      isTrialExpired () {
        return this.$store.getters.userCurrentWorkspaceObj.expired;
      }
    }

  }
</script>
