<template>
    <span></span>
</template>

<script>

import $ from 'jquery';
import ScNotification from '../../shared/common/ScNotification.vue';

/*

Simple polling pub-sub component.

We trialed WebSockets in Jun 2021 and ruled out due to high failure rate amongst our customers
see labs/2021 websockets.

Note ajax isn't as bad as it seems ad HTTP2 keeps the socket alive anyway technically it's no worse than websockets
it's just more noisy in the console.

 */

export default {
  name: 'ScOperationsV2',
  props: {
    subDomain: {type: String, default: null},
    subKey: {type: String, default: null},
    channelKey: {type: String, default: null},
    userId: {type: Number, default: null},
  },
  data() {
    return {
      updateSpeeds: [
        5 * 1000, 5 * 1000, 5 * 1000, 5 * 1000,   // 5s x 4 = 20s
        10 * 1000, 10 * 1000, 10 * 1000, 10 * 1000,  // 10s x 4 = 40s
        30 * 1000, 30 * 1000, 30 * 1000, 30 * 1000,  // 30s x 4 = 2m
        60 * 1000, 60 * 1000, 60 * 1000, 60 * 1000,  // 60s x 4 = 4m
        2 * 60 * 1000, 2 * 60 * 1000, 2 * 60 * 1000, 2 * 60 * 1000,  // 2m x 4 = 8m
        -1  // stop after 15m of no messages
      ],
      updateSpeedIdx: 0,
      checkInProgress: false,
      nextMinMsgSent: 0,
      checkTimeout: null,
    };
  },
  mounted() {
    document.addEventListener('click', this.userIsActive, {passive: true});

    let lsOpsNextMinMsgSent = null;
    try {
      if (window.localStorage) lsOpsNextMinMsgSent = window.localStorage.getItem('scOpsNextMinMsgSent');
    } catch(e) {
      // ignore
    }
    if (lsOpsNextMinMsgSent) this.nextMinMsgSent = Number(lsOpsNextMinMsgSent);

    this.queueNext();
  },
  beforeUnmount() {
      if (this.checkTimeout) clearTimeout(this.checkTimeout);
      document.removeEventListener('click', this.userIsActive, {passive: true});
  },
  methods: {
    userIsActive() {
      this.speedUp();
      this.queueNext();
    },
    speedUp() {
      this.updateSpeedIdx = 0;
    },
    slowDown() {
      this.updateSpeedIdx++;
      if (this.updateSpeedIdx > this.updateSpeeds.length-1) this.updateSpeedIdx = this.updateSpeeds.length-1 ;
    },
    queueNext() {
      let timeoutMs = this.updateSpeeds[this.updateSpeedIdx];
      //console.log(this.updateSpeedIdx, timeoutMs);
      if (timeoutMs > 0 && !this.checkTimeout) {
        this.checkTimeout = setTimeout(this.checkForMessages, this.updateSpeeds[this.updateSpeedIdx]);
      }
    },
    checkForMessages() {
      this.checkTimeout = null;
      if (this.checkInProgress) return;

      this.checkInProgress = true;
      $.ajax({
        type: 'GET', url: `https://${this.subDomain}/msgs_v1`,
        data: {min_msg_sent: this.nextMinMsgSent},
        beforeSend: (xhr) => {
          xhr.setRequestHeader('Authorization', `${this.subKey}:${this.channelKey}`);
        },
      }).done((data) => {
        this.checkInProgress = false;
        if (data && data.next_min_msg_sent) this.nextMinMsgSent = data.next_min_msg_sent;

        if (data && data.ps_messages && data.ps_messages.length > 0) {
            //console.log('messages', data.messages);
            data.ps_messages.forEach(this.handleMessage);
            this.speedUp();  // might be more messages to come
        } else {
            this.slowDown();  // less likely to be more messages
        }
        this.queueNext();

      }).fail(() => {
        this.checkInProgress = false;
        // we want to continue but slow down
        this.slowDown();  // this could be a connection blib or a server error, slow down in case
        this.queueNext();
      });
    },
    handleMessage(msg) {
      //console.log('ScOperationsV2.onMessage', msg);
      if (msg.ui_msg && msg.ui_msg_type && msg.user_id && this.userId
            && this.userId === msg.user_id) {
        if (msg.ui_msg && msg.ui_msg_type === 'error') {
          ScNotification.growlErrMsg(msg.ui_msg);
        } else if (msg.ui_msg) {
          ScNotification.growlSuccessMsg(msg.ui_msg);
        }
      }
      if (msg.event_name && msg.event_args) {
        document.dispatchEvent(new CustomEvent(`sc_event:${msg.event_name}`, {detail: msg.event_args}));
      }
    },
  },

  watch: {
    nextMinMsgSent() {
      try {
        if (window.localStorage) window.localStorage.setItem('scOpsNextMinMsgSent', this.nextMinMsgSent);
      } catch(e) {
        // ignore
      }
    }
  }
}

</script>

