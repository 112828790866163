<template>
    <ScLayoutNoAuth>
        <div>

        <div class="row">
            <div v-if="termsFetching" class="col-12 text-center">
                <ScIcon name="spinnerFW" class="mx-3 text-white" />
            </div>
            <div v-else class="col-12">
                <div v-if="userIsWorkshopOwner">
                    <h2 class="w-100 text-center text-white">Champion!</h2>

                    <p class="text-white">Your free trial is ready. What's your name?</p>
                </div>

                <form class="form my-2">

                    <div class="mb-2 form-floating">
                         <input class="form-control w-100" id="user_first_name" name="first_name" type="text"
                                :disabled="termsSaving" autocomplete="given-name"
                                placeholder="First name" v-model.trim="firstName" maxlength="128"/>
                         <label for="user_first_name" class="text-body">First name</label>
                    </div>

                    <div class="mb-2 form-floating">
                         <input class="form-control w-100" id="user_last_name" name="last_name" type="text"
                                :disabled="termsSaving" autocomplete="given-name"
                                placeholder="Last name" v-model.trim="lastName" maxlength="128"/>
                         <label for="user_last_name" class="text-body">Last name</label>
                    </div>


                    <div class="mt-4">
                        <div v-if="!isCrmDisabledForWorkshop" class="form-check mt-2">
                            <input class="form-check-input" type="checkbox" id="newsletter_opt_in" v-model="newsletterOptIn">
                            <label class="form-check-label text-white" for="newsletter_opt_in">
                                    I'd like to get newsletters with updates on new features, promotions and giveaways.</label>
                        </div>
                        <div v-if="!isCrmDisabledForWorkshop" class="mt-3 form-check">
                                <input class="form-check-input" type="checkbox" name="opt_in_email" id="opt_in_email"
                                       value="false" v-model="onboardingOptIn">
                                <label class="form-check-label text-white" for="opt_in_email">I'd also like to get 2-3 helpful
                                    emails to get me started with Showcase.</label>
                        </div>
                    </div>

                    <div class="text-center mt-5">
                         <button @click.prevent="saveAccountConfirm()" :disabled="termsSaving"
                                type="submit" class="btn btn-lg btn-primary-green fw-bold shadow">
                             <ScIcon v-if="termsSaving" name="spinnerFW" class="mx-3" />
                             <span class="mx-3" v-else>Start now!</span></button>
                    </div>

                    <div class="mt-4 text-center">
                        <small>By clicking 'Start now!' you agree to the
                            <br/><a href="https://showcaseworkshop.com/legal/" target="_blank" class="text-decoration-underline">Terms of Use</a>
                            and our
                            <a href="https://showcaseworkshop.com/privacy/" target="_blank" class="text-decoration-underline">Privacy Policy</a>.
                        </small>
                    </div>

                    <div class="my-3 text-center">
                        <button type="button" class="btn btn-link text-white" @click.prevent="cancelTerms">
                                Cancel</button>
                    </div>

                </form>
            </div>

        </div>
    </div>
    </ScLayoutNoAuth>

</template>

<script>

    import $ from 'jquery';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScLayoutNoAuth from "../global/ScLayoutNoAuth.vue";
    import MainAppMixin from "../MainAppMixin";

    export default {
        name: "AcceptTermsForm",
        mixins: [ MainAppMixin ],
        components: {ScLayoutNoAuth, ScIcon},
        data() {
            return {
                firstName: null,
                lastName: null,
                newsletterOptIn: false,
                onboardingOptIn: false,
                clientName: 'browser',
                userIsWorkshopOwner: false,
                isCrmDisabledForWorkshop: false,

                termsSaving: false,
                termsFetching: false
            }
        },
        mounted () {
            this.fetchTermsInfo();
        },
        methods: {
            validateModel  () {
                let fieldsEmpty = [];
                if (!this.firstName) {
                    fieldsEmpty.push('firstName');
                    ScNotification.growlErrMsg('Please enter your first name')
                }
                return fieldsEmpty.length === 0;
            },

            fetchTermsInfo() {
                this.termsFetching = true;
                $.ajax({
                    method: 'post', url: '/main/signup/ajax_fetch_terms_v2', data: {
                        workspace_id: this.$store.state.user.currentWorkshopId
                    }
                }).then((data) => {
                    this.termsFetching = false;
                    if (data.status === 'ok') {
                        this.userIsWorkshopOwner = data.is_workshop_owner;
                        this.firstName = data.first_name;
                        this.lastName = data.last_name;
                        this.newsletterOptIn = data.newsletter_opt_in;
                        this.onboardingOptIn = data.onboarding_opt_in;
                        this.isCrmDisabledForWorkshop = data.workspace_is_crm_disabled;
                    } else {
                        ScNotification.growlErrMsg(data.details);
                    }
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'fetching user info');
                    this.termsFetching = false;
                });
            },

            saveAccountConfirm () {
                ScNotification.growlClear();
                let valid = this.validateModel();
                if (valid) {
                    this.termsSaving = true;
                    $.ajax({
                        method: 'post', url: '/main/signup/ajax_agree_terms_v2', data: {
                            first_name: this.firstName,
                            last_name: this.lastName,
                            newsletter_opt_in: this.newsletterOptIn,
                            onboarding_opt_in: this.onboardingOptIn,
                            accept_terms: true,
                            client_name: this.clientName,
                            signup_source: this.signup_source,
                            workspace_id: this.mainMxCurrentWorkshopId
                        }
                    }).then((data) => {
                        this.termsSaving = false;
                        if (data.status === 'ok') {
                            this.acceptTermsDone();
                        } else {
                            ScNotification.growlErrMsg(data.details);
                        }
                    }).fail((jqXhr) => {
                        ScNotification.growlXhrError(jqXhr, 'accepting terms');
                        this.termsSaving = false;
                    });
                }
            },

            acceptTermsDone: function() {
                this.$store.commit('userTermsAccepted', true);
                if (this.userIsWorkshopOwner) this.$store.commit('userShowGuide', true);
            },
            cancelTerms: function() {
                this.$store.dispatch('userLogout');
            }
        },
    }

</script>

