<template>
  <div>
    <transition name="sc-transition-fade">
      <div v-if="helpWidgetShown">
        <div class="container-fluid p-0">
          <div class="row g-0" style="min-height: 100%;">
            <div class="help-widget-close-area" @click.prevent="toggleHelpWidget(false)"></div>

            <div class="shadow-lg help-widget-panel">
              <div class="help-widget-close">
                <a class="p-2 me-2 shadow-lg text-white display-4 fw-bold"
                   href="#" title="Close help panel"
                   @click.prevent="toggleHelpWidget(false)">×</a>
              </div>
              <div class="row g-0 help-widget-header py-1">
                <div class="col text-center">
                  <a :class="['btn text-white help-nav-item', helpMode==='get-started'? 'active fw-bold':'']"
                     href="#" @click.prevent="setHelpMode('get-started')">Get started</a>
                </div>
                <div class="col text-center">
                  <a :class="['btn text-white help-nav-item', helpMode==='kb'? 'active fw-bold':'']"
                     href="#" @click.prevent="setHelpMode('kb')">Knowledge Base</a>
                </div>
                <div class="col text-center">
                  <a :class="['btn text-white help-nav-item', helpMode==='contact'? 'active fw-bold':'']"
                     href="#" @click.prevent="setHelpMode('contact')">Contact</a>
                </div>
              </div>

              <div v-if="helpMode === 'contact'" class="help-widget-div">

                <div class="container my-3">
                  <div class="row g-0 justify-content-center">
                    <div class="col-10 my-3">
                      <h3>Always here to help</h3>
                      <p>Get in touch using the form below and we’ll get back to you within 24 hours. Nice!</p>

                      <form @submit.prevent class="mx-0">
                        <div class="mb-3 form-floating">
                          <input class="form-control w-100" id="user-email" name="user-email" type="text"
                                 placeholder="Your email address"
                                 v-model.trim="supportUserEmail" :disabled="supportTicketSending" />
                          <label for="user-email" class="text-body">Your email address</label>
                        </div>
                        <div class="mb-3 form-floating">
                          <input class="form-control w-100" id="helpdesk-subject" name="helpdesk-subject" type="text"
                                 placeholder="Subject"
                                 v-model.trim="supportSubject" :disabled="supportTicketSending" />
                          <label for="helpdesk-subject" class="text-body">Subject</label>
                        </div>
                        <div class="mb-3 form-floating">
                          <input class="form-control w-100" id="helpdesk-body" name="helpdesk-body" type="text"
                                 placeholder="Tell us all about it!"
                                 v-model.trim="supportBody" :disabled="supportTicketSending" />
                          <label for="helpdesk-body" class="text-body">Tell us all about it!</label>
                        </div>
                        <div class="my-4 text-center">
                          <button class="btn btn-primary-orange fw-bold" @click.prevent="sendSupportRequest()"
                                  :disabled="supportTicketSending">Send</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-center pb-5">
                  <div class="col-10 text-center">
                    <p style="line-height: 100%">
                      <small>Psst! We also offer
                        <a href="https://showcaseworkshop.com/demos-tutorials-and-training#training" target="_blank">video
                          call tutorials</a> to help you get a handle on Showcase.
                      </small>
                    </p>
                  </div>
                </div>
              </div>


              <iframe v-if="helpMode === 'kb'" class="help-widget-iframe"
                      src="https://support.showcaseworkshop.com/help"></iframe>


              <div v-if="helpMode === 'get-started'" class="help-widget-ob-list">
                <OnboardingChecklist></OnboardingChecklist>
              </div>


            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="sc-transition-fade">
      <div class="help-widget-wrap" v-if="!helpWidgetShown && showHelpWidgetBtn">
        <div v-if="showGetStartedTooltip" class="guide-tooltip" role="tooltip">
          <div class="guide-tooltip-arrow"></div>
          <div>Find the guide again here!</div>
        </div>

        <a class="btn pt-2 px-3 text-center sc-help-widget text-white"
           @click.prevent="toggleHelpWidget(true)">Help?</a>
      </div>
    </transition>

  </div>
</template>

<script>

    import $ from 'jquery';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import OnboardingChecklist from '../account/OnboardingChecklist.vue';

    export default {
      name: 'ScHelpWidget',
      components: { OnboardingChecklist },
      data () {
        return {
          helpWidgetShown: false,
          helpMode: 'get-started',
          showHelpWidgetBtn: false,
          supportTicketSending: false,
          supportUserEmail: null,
          supportSubject: null,
          supportBody: null,
          showGetStartedTooltip: false,
        }
      },
      mounted () {
        this.resetSupportFields();
        if (this.showGuide) {
          this.helpWidgetShown = true;
          this.showGetStartedTooltip = true;
          this.$store.commit('userShowGuide', false);
        }
        setTimeout(() => {
          this.showHelpWidgetBtn = true;
        }, 50);//5000);
        this.checkUserPlan();

        window.addEventListener('message', this.windowOnMessage, false);
      },
      beforeUnmount: function() {
        window.removeEventListener('message', this.windowOnMessage, false);
      },
      methods: {
        resetSupportFields () {
          this.supportUserEmail = this.$store.state.user.email;
          this.supportSubject = null;
          this.supportBody = null;
        },
        toggleHelpWidget (show) {
          this.helpWidgetShown = show;
          //console.log('hide and show tooltip', show, this.showGetStartedTooltip);
          if (!show && this.showGetStartedTooltip) {
            setTimeout(() => {
              this.showGetStartedTooltip = false;
            }, 5000);
          }
        },
        setHelpMode (helpMode) {
          this.helpMode = helpMode;
        },
        windowOnMessage (e) {
          /* scHelpWidgetClose triggered from: https://showcaseworkshop.com/webapp-guide */
          if (e && e.data && e.data === 'scHelpWidgetClose') {
            this.helpWidgetShown = false;
          }
        },
        sendSupportRequest () {
          if (!this.supportUserEmail) {
            ScNotification.growlErrMsg('Please enter an email');
            return;
          }
          if (!this.supportSubject) {
            ScNotification.growlErrMsg('Please enter a subject');
            return;
          }
          if (!this.supportBody) {
            ScNotification.growlErrMsg('Please enter a body');
            return;
          }
          this.supportTicketSending = true;
          $.ajax({
            method: 'POST', url: '/main/default/ajax_send_help_ticket',
            data: {
              ticket_from: this.supportUserEmail,
              ticket_subject: this.supportSubject,
              ticket_body: this.supportBody,
              ticket_page_url: document.location.href,
              ticket_agent: navigator.userAgent
            }
          }).done(() => {
            ScNotification.growlSuccessMsg('Thanks!');
            this.resetSupportFields();

          }).always(() => {
            this.supportTicketSending = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'sending support ticket');

          });
        },
        checkUserPlan () {
          if (this.currentWorkshopObj && !this.currentWorkshopObj.is_trial_plan) {
            this.setHelpMode('kb');
          }
        }
      },
      computed: {
        showGuide () {
          return this.$store.state.user.showUserGuide;
        },
        currentWorkshopObj() {
          return this.$store.getters.userCurrentWorkspaceObj;
        },
      },
      watch: {
        showGuide (show) { // check if triggered by trial banner
          if (show) {
            this.helpMode = 'get-started';
            this.toggleHelpWidget(show);
            this.$store.commit('userShowGuide', false);
          }
        }
      }
    }

</script>


<style scoped>

  .help-widget-wrap {
    z-index: 10000; /* see README.md webapp z-index values */
    position: fixed;
    bottom: 30px;
    right: 30px;
  }

  .sc-help-widget {
    background-color: #00a8ca;
    border-radius: 11px;
    border: 3px solid white;
    font-size: 20px;
    display: inline-block;
    opacity: .8;
    box-shadow: 2px 2px 27px -4px rgba(0,0,0,0.4);
  }

  .sc-help-widget:hover {
    box-shadow: none;
    color: white !important;
  }

  .sc-help-widget:active {
    background-color: #008ab0;
  }


  .help-widget-panel {
    z-index: 10000; /* see README.md webapp z-index values */
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 768px;
    background-color: #f5f5f5;
    border-left: 2px solid #e9e9f2;
  }

  .help-widget-iframe {
    border: none;
    height: calc(100% - 50px);
    width: 768px;
    background-color: #f5f5f5;
  }

  .help-widget-close-area {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 768px);
    height: 100%;
    opacity: 0.5;
    background-color: black;
    z-index: 9990; /* see README.md webapp z-index values */
  }

  .help-widget-close {
    position: fixed;
    right: 768px;
    top: 0;
  }

  .help-widget-ob-list {
    height: 85%;
    overflow: auto;
  }

  @media only screen and (max-width: 768px) {
    .help-widget-panel, .help-widget-iframe {
      width: 576px;
    }
    .help-widget-close-area {
      width: calc(100% - 576px);
    }
    .help-widget-close {
      right: 576px;
    }
  }
  @media only screen and (max-width: 576px) {
    .help-widget-panel, .help-widget-iframe {
      width: 320px;
    }
    .help-widget-close-area {
      width: calc(100% - 320px);
    }
    .help-widget-close {
      right: 320px;
    }
  }
  @media only screen and (max-width: 320px) {
    .help-widget-panel, .help-widget-iframe {
      width: 280px;
    }
    .help-widget-close-area {
      width: calc(100% - 280px);
    }
    .help-widget-close {
      right: 280px;
    }
  }

  .help-widget-header {
    /*height: 50px;*/
    background: #008ab0;
    background: -moz-linear-gradient(left, #008ab0 0%, #26bcd6 100%);
    background: -webkit-linear-gradient(left, #008ab0 0%,#26bcd6 100%);
    background: linear-gradient(to right, #008ab0 0%,#26bcd6 100%);
  }


  body.scviewer-active .help-widget-wrap {
    display: none;
  }

  .guide-tooltip {
    max-width: 140px;
    padding: 7px 8px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background-color: #000;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 1.2rem;
  }

  .guide-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    border-top-color: #000;
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    top: 70px;
  }

</style>