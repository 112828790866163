<template>
  <span>
    <template v-if="!getIcon.sizeObj">
      <span :class="['si',
          getIcon.spin ? ' si-spin': '',
          !getIcon.size ? 'si-size-1x': '',
          getIcon.size === '1x' ? 'si-size-1x': '',
          getIcon.size === '2x' ? 'si-size-2x': '',
          getIcon.size === '3x' ? 'si-size-3x': '',
          getIcon.size === '4x' ? 'si-size-4x': '',
          getIcon.size === '6x' ? 'si-size-6x': '',
          getIcon.transform === 'shrink-9' ? 'si-shrink-9': '',
          getIcon.transform === 'horizontal-grow-8' ? 'si-horizontal-grow-8': '',
          getIcon.fixedWidth ? 'si-fw': '',
          getIcon.rotation === '270' ? 'si-rotate-270' : '',
          getIcon.rotation === '90' ? 'si-rotate-90' : '',
          getIcon.flip === 'horizontal' ? 'si-flip-horiz' : '',
        ]" v-html="getIcon.fc">
     </span>
    </template>
    <template v-else >
      <span :style="getIcon.sizeObj" class="si-stack">
        <span :class="['si si-stack-1x',
          getIcon.layerOne.spin ? ' si-spin': '',
          !getIcon.layerOne.size ? 'si-size-1x': '',
          getIcon.layerOne.size === '1x' ? 'si-size-1x': '',
          getIcon.layerOne.size === '2x' ? 'si-size-2x': '',
          getIcon.layerOne.size === '3x' ? 'si-size-3x': '',
          getIcon.layerOne.size === '4x' ? 'si-size-4x': '',
          getIcon.layerOne.size === '6x' ? 'si-size-6x': '',
          getIcon.layerOne.transform === 'horizontal-grow-8' ? 'si-horizontal-grow-8': '',
          getIcon.layerOne.fixedWidth ? 'si-fw': '',
          getIcon.layerOne.rotation === '270' ? 'si-rotate-270' : '',
          getIcon.layerOne.rotation === '90' ? 'si-rotate-90' : '',
          getIcon.layerOne.flip === 'horizontal' ? 'si-flip-horiz' : '',
        ]" v-html="getIcon.layerOne.fc" :style="getIcon.layerOne.styleObj"></span>
        <span :class="['si si-stack-2x',
          getIcon.layerTwo.spin ? ' si-spin': '',
          !getIcon.layerTwo.size ? 'si-size-1x': '',
          getIcon.layerTwo.size === '1x' ? 'si-size-1x': '',
          getIcon.layerTwo.size === '2x' ? 'si-size-2x': '',
          getIcon.layerTwo.size === '3x' ? 'si-size-3x': '',
          getIcon.layerTwo.size === '4x' ? 'si-size-4x': '',
          getIcon.layerTwo.size === '6x' ? 'si-size-6x': '',
          getIcon.layerTwo.transform === 'shrink-9' ? 'si-shrink-9': '',
          getIcon.layerTwo.transform === 'shrink-5' ? 'si-shrink-5': '',
          getIcon.layerTwo.fixedWidth ? 'si-fw': '',
          getIcon.layerTwo.rotation === '270' ? 'si-rotate-270' : '',
          getIcon.layerTwo.rotation === '90' ? 'si-rotate-90' : '',
          getIcon.layerTwo.flip === 'horizontal' ? 'si-flip-horiz' : '',
        ]" v-html="getIcon.layerTwo.fc" :style="getIcon.layerTwo.styleObj"></span>
      </span>
    </template>
  </span>
</template>

<script>

  import SvgIcons from './svg-icons.json'

  const _iconMap = {
    'default' : {fl: 'far', fc: SvgIcons.faCircle},

    'alignCenterFW' : {fl: 'far', fc: SvgIcons.faAlignCenter, fixedWidth: true},
    'alignLeftFW' : {fl: 'far', fc: SvgIcons.faAlignLeft, fixedWidth: true},
    'alignRightFW' : {fl: 'far', fc: SvgIcons.faAlignRight, fixedWidth: true},
    'angleLeft' : {fl: 'far', fc: SvgIcons.faAngleLeft},
    'angleLeftFW' : {fl: 'far', fc: SvgIcons.faAngleLeft, fixedWidth: true},
    'angleDown' : {fl: 'far', fc: SvgIcons.faAngleDown},
    'angleUp' : {fl: 'far', fc: SvgIcons.faAngleUp},
    'arrowCircleDownSmall' : {fl: 'far', fc: SvgIcons.faCircleArrowDown, size: '2x'},
    'arrowFromLeftFW' : {fl: 'far', fc: SvgIcons.faArrowRightFromLine},
    'arrowsUpDownLeftRightFW': {fl:'far', fc: SvgIcons.faArrowsUpDownLeftRight, fixedWidth: true},
    'asterisk' : {fl: 'far', fc: SvgIcons.faAsterisk},
    'asteriskFW' : {fl: 'far', fc: SvgIcons.faAsterisk, fixedWidth: true},

    'backspace' : {fl: 'far', fc: SvgIcons.faDeleteLeft},
    'boldFW' : {fl: 'far', fc: SvgIcons.faBold, fixedWidth: true},
    'borderOuterFW' : {fl: 'far', fc: SvgIcons.faBorderOuter, fixedWidth: true},
    'boxArchiveSmall' : {fl: 'far', fc: SvgIcons.faBoxArchive, size: '2x'},
    'browserFW' : {fl: 'far', fc: SvgIcons.faBrowser, fixedWidth: true},

    'calendarAltFW' : {fl: 'far', fc: SvgIcons.faCalendarRange, fixedWidth: true},
    'caretSquareDownFW' : {fl: 'far', fc: SvgIcons.faSquareCaretDown, fixedWidth: true},
    'caretSquareRightFW' : {fl: 'far', fc: SvgIcons.faSquareCaretRight, fixedWidth: true},
    'caretSquareUpFW' : {fl: 'far', fc: SvgIcons.faSquareCaretUp, fixedWidth: true},
    'chartBarFW' : {fl: 'far', fc: SvgIcons.faChartColumn, fixedWidth: true},
    'chartLineFW' : {fl: 'far', fc: SvgIcons.faChartLine, fixedWidth: true},
    'check' : {fl: 'far', fc: SvgIcons.faCheck},
    'checkFas' : {fl: 'fas', fc: SvgIcons.faCheckFas},
    'checkSquareFW' : {fl: 'far', fc: SvgIcons.faSquareCheck, fixedWidth: true},
    'chevronLeftFW' : {fl: 'far', fc: SvgIcons.faChevronLeft, fixedWidth: true},
    'chevronRightFW' : {fl: 'far', fc: SvgIcons.faChevronRight, fixedWidth: true},
    'circleFW' : {fl: 'far', fc: SvgIcons.faCircle, fixedWidth: true},
    'circleFWSmall' : {fl: 'far', fc: SvgIcons.faCircle, fixedWidth: true, size: '2x'},
    'circleSmall' : {fl: 'far', fc: SvgIcons.faCircle, size: '2x'},
    'clockFW' : {fl: 'far', fc: SvgIcons.faClock, fixedWidth: true},
    'clone' : {fl: 'far', fc: SvgIcons.faRectangleVerticalHistory, rotation: '270'},
    'cloneFW' : {fl: 'far', fc: SvgIcons.faRectangleVerticalHistory, fixedWidth: true, rotation: '270'},
    'cloneSmall' : {fl: 'far', fc: SvgIcons.faRectangleVerticalHistory, size: '2x', rotation: '270'},
    'cloudDownloadFWSmall' : {fl: 'far', fc: SvgIcons.faCloudArrowDown, fixedWidth: true, size: '2x'},
    'cloudDownloadFWFlip' : {fl: 'far', fc: SvgIcons.faCloudArrowDown, fixedWidth: true, flip: 'horizontal'},
    'cloudDownloadFWFlipSmall' : {fl: 'far', fc: SvgIcons.faCloudArrowDown, fixedWidth: true, flip: 'horizontal', size: '2x'},
    'cloudUploadFW' : {fl: 'far', fc: SvgIcons.faCloudArrowUp, fixedWidth: true},
    'cloudUploadSmall' : {fl: 'far', fc: SvgIcons.faCloudArrowUp, size: '2x'},
    'cogSmall' : {fl: 'far', fc: SvgIcons.faGear, size: '2x'},
    'copy' : {fl: 'far', fc: SvgIcons.faClone},
    'copyFW' : {fl: 'far', fc: SvgIcons.faClone, fixedWidth: true},

    'distributeSpacingHorizontalFW' : {fl: 'far', fc: SvgIcons.faDistributeSpacingHorizontal, fixedWidth: true},
    'distributeSpacingVerticalFW' : {fl: 'far', fc: SvgIcons.faDistributeSpacingVertical, fixedWidth: true},
    'download' : {fl: 'far', fc: SvgIcons.faDownload},
    'downloadFW' : {fl: 'far', fc: SvgIcons.faDownload, fixedWidth: true},

    'editFW' : {fl: 'far', fc: SvgIcons.faPenToSquare, fixedWidth: true},
    'editSmall' : {fl: 'far', fc: SvgIcons.faPenToSquare, size: '2x'},
    'ellipsisHFW' : {fl: 'far', fc: SvgIcons.faEllipsis, fixedWidth: true},
    'ellipsisHFWSmall' : {fl: 'far', fc: SvgIcons.faEllipsis, fixedWidth: true, size: '2x'},
    'envelope' : {fl: 'far', fc: SvgIcons.faEnvelope},
    'envelopeFW' : {fl: 'far', fc: SvgIcons.faEnvelope, fixedWidth: true},
    'envelopeOpen' : {fl: 'far', fc: SvgIcons.faEnvelopeOpen},
    'envelopeOpenFWSmall' : {fl: 'far', fc: SvgIcons.faEnvelopeOpen, size: '2x'},
    'exclamationTriangleFW' : {fl: 'far', fc: SvgIcons.faTriangleExclamation, fixedWidth: true},
    'exclamationTriangleFWSmall' : {fl: 'far', fc: SvgIcons.faTriangleExclamation, fixedWidth: true, size: '2x'},
    'externalLink' : {fl: 'far', fc: SvgIcons.faArrowUpRightFromSquare},
    'externalLinkFW' : {fl: 'far', fc: SvgIcons.faArrowUpRightFromSquare, fixedWidth: true},
    'eyesFW' : {fl: 'far', fc: SvgIcons.faEyes, fixedWidth: true},

    'fileFW' : {fl: 'far', fc: SvgIcons.faFile, fixedWidth: true},
    'fileSmall' : {fl: 'far', fc: SvgIcons.faFile, size: '2x'},
    'fileAltFW' : {fl: 'far', fc: SvgIcons.faFileLines, fixedWidth: true},
    'fileAltFWSmall' : {fl: 'far', fc: SvgIcons.faFileLines, fixedWidth: true, size: '2x'},
    'fileCheck' : {fl: 'far', fc: SvgIcons.faFileCheck},
    'fileImageFW' : {fl: 'far', fc: SvgIcons.faFileImage, fixedWidth: true},
    'fileVideoFW' : {fl: 'far', fc: SvgIcons.faFileVideo, fixedWidth: true},
    'filmFW' : {fl: 'far', fc: SvgIcons.faFilm, fixedWidth: true},
    'filterFW' : {fl: 'far', fc: SvgIcons.faFilter, fixedWidth: true },
    'folderPlusFW' : {fl: 'far', fc: SvgIcons.faFolderPlus, fixedWidth: true },
    'folderLarge' : {fl: 'far', fc: SvgIcons.faFolderBlank, size: '6x'},
    'folderSmallMedium' : {fl: 'far', fc: SvgIcons.faFolderBlank, size: '3x'},
    'fontFW' : {fl: 'far', fc: SvgIcons.faFont, fixedWidth: true},
    'frownSmall' : {fl: 'far', fc: SvgIcons.faFaceFrownSlight, size: '2x'},

    'handPointLeftFW' : {fl: 'far', fc: SvgIcons.faHandPointLeft, fixedWidth: true},
    'handPointRightFW' : {fl: 'far', fc: SvgIcons.faHandPointRight, fixedWidth: true},
    'hashtag' : {fl: 'far', fc: SvgIcons.faHashtag},
    'hashtagFW' : {fl: 'far', fc: SvgIcons.faHashtag, fixedWidth: true},
    'hashtagSmall' : {fl: 'far', fc: SvgIcons.faHashtag, size: '2x'},
    'hashtagFWSmall' : {fl: 'far', fc: SvgIcons.faHashtag, fixedWidth: true, size: '2x'},
    'highlighterFW' : {fl: 'far', fc: SvgIcons.faHighlighter, fixedWidth: true},

    'idBadge' : {fl: 'far', fc: SvgIcons.faIdBadge},
    'infoCircle' : {fl: 'far', fc: SvgIcons.faCircleInfo},
    'infoCircleSmall' : {fl: 'far', fc: SvgIcons.faCircleInfo, size: '2x'}, // NOT used in webapp but apps!!
    'infoCircleFW' : {fl: 'far', fc: SvgIcons.faCircleInfo, fixedWidth: true},
    'imageFW' : {fl: 'far', fc: SvgIcons.faImage, fixedWidth: true},
    'imageMedium' : {fl: 'far', fc: SvgIcons.faImage, size: '4x'},
    'italicFW' : {fl: 'far', fc: SvgIcons.faItalic, fixedWidth: true},

    'keynoteFW': {fl: 'far', fc: SvgIcons.faKeynote, fixedWidth: true},
    'keynoteFWSmall': {fl: 'far', fc: SvgIcons.faKeynote, fixedWidth: true, size: '2x'},

    'levelUpFWR' : {fl: 'far', fc: SvgIcons.faArrowTurnUp, fixedWidth: true, rotation: '90'},
    'lineHeightFW' : {fl: 'far', fc: SvgIcons.faLineHeight, fixedWidth: true},
    'link' : {fl: 'far', fc: SvgIcons.faLink},
    'linkFW' : {fl: 'far', fc: SvgIcons.faLink, fixedWidth: true},
    'listFW' : {fl: 'far', fc: SvgIcons.faList, fixedWidth: true},
    'lock' : {fl: 'far', fc: SvgIcons.faLock},
    'lockFW' : {fl: 'far', fc: SvgIcons.faLock, fixedWidth: true},
    'longArrowRight' : {fl: 'far', fc: SvgIcons.faArrowRightLong},
    'lowVision' : {fl: 'far', fc: SvgIcons.faEyeLowVision},
    'lowVisionFW' : {fl: 'far', fc: SvgIcons.faEyeLowVision, fixedWidth: true},

    'mapMarkerQuestionFW' : {fl: 'far', fc: SvgIcons.faLocationQuestion, fixedWidth: true},
    'minusCircleFW' : {fl: 'far', fc: SvgIcons.faCircleMinus, fixedWidth: true},
    'minusCircleSmall' : {fl: 'far', fc: SvgIcons.faCircleMinus, size: '2x'},

    'objectsAlignBottomFW' : {fl: 'far', fc: SvgIcons.faObjectsAlignBottom, fixedWidth: true},
    'objectsAlignCenterHorizontalFW' : {fl: 'far', fc: SvgIcons.faObjectsAlignCenterHorizontal, fixedWidth: true},
    'objectsAlignCenterVerticalFW' : {fl: 'far', fc: SvgIcons.faObjectsAlignCenterVertical, fixedWidth: true},
    'objectsAlignLeftFW' : {fl: 'far', fc: SvgIcons.faObjectsAlignLeft, fixedWidth: true},
    'objectsAlignRightFW' : {fl: 'far', fc: SvgIcons.faObjectsAlignRight, fixedWidth: true},
    'objectsAlignTopFW' : {fl: 'far', fc: SvgIcons.faObjectsAlignTop, fixedWidth: true},

    'paperPlaneFW' : {fl: 'far', fc: SvgIcons.faPaperPlane, fixedWidth: true},
    'pencil' : {fl: 'far', fc: SvgIcons.faPencil},
    'pencilFW' : {fl: 'far', fc: SvgIcons.faPencil, fixedWidth: true},
    'playCircle' : {fl: 'far', fc: SvgIcons.faCirclePlay},
    'playCircleFW' : {fl: 'far', fc: SvgIcons.faCirclePlay, fixedWidth: true},
    'plus' : {fl: 'far', fc: SvgIcons.faPlus},
    'plusFW' : {fl: 'far', fc: SvgIcons.faPlus, fixedWidth: true},
    'plusSmall' : {fl: 'far', fc: SvgIcons.faPlus, size: '2x'},
    'plusMedium' : {fl: 'far', fc:  SvgIcons.faPlus, size: '4x'},
    'plusSquareFW' : {fl: 'far', fc:  SvgIcons.faSquarePlus, fixedWidth: true},

    'questionCircle' : {fl: 'far', fc: SvgIcons.faCircleQuestion},
    'questionCircleFW' : {fl: 'far', fc: SvgIcons.faCircleQuestion, fixedWidth: true},

    'randomFW' : {fl: 'far', fc: SvgIcons.faShuffle, fixedWidth: true},
    'redoFW' : {fl: 'far', fc: SvgIcons.faArrowRotateRight, fixedWidth: true},
    'replyFW' : {fl: 'far', fc: SvgIcons.faReply, fixedWidth: true},
    'resizeFW': {fl: 'far', fc: SvgIcons.faArrowDownSmallBig, fixedWidth: true},
    'rocketFW' : {fl: 'far', fc: SvgIcons.faRocket, fixedWidth: true},
    'rocketSmall' : {fl: 'far', fc: SvgIcons.faRocket, size: '2x'},

    'search' : {fl: 'far', fc: SvgIcons.faMagnifyingGlass},
    'searchFW' : {fl: 'far', fc: SvgIcons.faMagnifyingGlass, fixedWidth: true},
    'searchFWSmall' : {fl: 'far', fc: SvgIcons.faMagnifyingGlass, fixedWidth: true, size: '2x'},
    'searchMinusFW' : {fl: 'far', fc: SvgIcons.faMagnifyingGlassMinus, fixedWidth: true},
    'searchPlusFW' : {fl: 'far', fc: SvgIcons.faMagnifyingGlassPlus, fixedWidth: true},
    'searchMinusFWSmallMedium' : {fl: 'far', fc: SvgIcons.faMagnifyingGlassMinus, fixedWidth: true, size: '3x'},
    'searchPlusFWSmallMedium' : {fl: 'far', fc: SvgIcons.faMagnifyingGlassPlus, fixedWidth: true, size: '3x'},
    'shareFW': {fl: 'far', fc: SvgIcons.faShare, fixedWidth: true},
    'shareSquareFW': {fl: 'far', fc: SvgIcons.faShareFromSquare, fixedWidth: true},
    'shareSquareFWSmall': {fl: 'far', fc: SvgIcons.faShareFromSquare, fixedWidth: true, size: '2x'},
    'slidersHFW': {fl: 'far', fc: SvgIcons.faSliders, fixedWidth: true},
    'sortFWR': {fl: 'far', fc: SvgIcons.faSort, fixedWidth: true, rotation: '90'},
    'sortAlphaDownFW': {fl: 'far', fc: SvgIcons.faArrowDownAZ, fixedWidth: true},
    'sortAmountDownFW': {fl: 'far', fc: SvgIcons.faArrowDownShortWide, fixedWidth: true},
    'sparklesFW': {fl: 'far', fc: SvgIcons.faSparkles, fixedWidth: true},
    'squareFW': {fl: 'far', fc: SvgIcons.faSquare, fixedWidth: true},
    'stream': {fl: 'far', fc: SvgIcons.faBarsStaggered},
    'strikethroughFW': {fl: 'far', fc: SvgIcons.faStrikethrough, fixedWidth: true},
    'subscriptFW': {fl: 'far', fc: SvgIcons.faSubscript, fixedWidth: true},
    'superscriptFW': {fl: 'far', fc: SvgIcons.faSuperscript, fixedWidth: true},
    'syncAltFW' : {fl: 'far', fc: SvgIcons.faArrowsRotate, fixedWidth: true},
    'syncAltFWSpin' : {fl: 'far', fc: SvgIcons.faArrowsRotate, spin: true, fixedWidth: true},
    'syncAltFWSmall' : {fl: 'far', fc: SvgIcons.faArrowsRotate, fixedWidth: true, size: '2x'},

    'spinnerFW' : {fl: 'far', fc: SvgIcons.faCircleNotch, spin: true, fixedWidth: true},
    'spinnerSmall' : {fl: 'far', fc: SvgIcons.faCircleNotch, size: '2x', spin: true },
    'spinnerMedium' : {fl: 'far', fc: SvgIcons.faCircleNotch, size: '4x', spin: true},

    'tabletAltFW': {fl: 'fas', fc: SvgIcons.faTabletScreenButton, fixedWidth: true},
    'tag': {fl: 'far', fc: SvgIcons.faTag},
    'tagFW': {fl: 'far', fc: SvgIcons.faTag, fixedWidth: true},
    'tagSmall': {fl: 'far', fc: SvgIcons.faTag, size: '2x'},
    'tagsFW': {fl: 'far', fc: SvgIcons.faTags, fixedWidth: true},
    'thLargeFW': {fl: 'far', fc: SvgIcons.faTableCellsLarge, fixedWidth: true},
    'times': {fl: 'far', fc: SvgIcons.faXmark},
    'timesFW': {fl: 'far', fc: SvgIcons.faXmark, fixedWidth: true},
    'timesFWSmall': {fl: 'far', fc: SvgIcons.faXmark, fixedWidth: true, size: '2x'},
    'tintSlash': {fl: 'far', fc: SvgIcons.faDropletSlash},
    'tintSlashFW': {fl: 'far', fc: SvgIcons.faDropletSlash, fixedWidth: true},
    'trashAlt': {fl: 'far', fc: SvgIcons.faTrashCan},
    'trashAltFW': {fl: 'far', fc: SvgIcons.faTrashCan, fixedWidth: true},
    'trophyFW': {fl: 'far', fc: SvgIcons.faTrophy, fixedWidth: true},

    'underlineFW' : {fl: 'far', fc: SvgIcons.faUnderline, fixedWidth: true},
    'undoFW' : {fl: 'far', fc: SvgIcons.faArrowRotateLeft, fixedWidth: true},
    'undoSmall' : {fl: 'far', fc: SvgIcons.faArrowRotateLeft, size: '2x'},
    'university' : {fl: 'far', fc: SvgIcons.faLandmark},
    'unlockAltFW' : {fl: 'far', fc: SvgIcons.faUnlockKeyhole, fixedWidth: true},
    'upload' : {fl: 'far', fc: SvgIcons.faUpload},
    'user' : {fl: 'far', fc: SvgIcons.faUser},
    'userFW' : {fl: 'far', fc: SvgIcons.faUser, fixedWidth: true},
    'userSmall' : {fl: 'far', fc: SvgIcons.faUser, size: '2x'},
    'userSmallMedium' : {fl: 'far', fc: SvgIcons.faUser, size: '3x'},
    'userAstronautFW' : {fl: 'far', fc: SvgIcons.faUserAstronaut, fixedWidth: true},
    'userAstronautSmallMedium' : {fl: 'far', fc: SvgIcons.faUserAstronaut, size: '3x'},
    'userBountyHunterFW' : {fl: 'far', fc: SvgIcons.faUserBountyHunter, fixedWidth: true},
    'userBountyHunterSmallMedium' : {fl: 'far', fc: SvgIcons.faUserBountyHunter, size: '3x'},
    'userNinjaFW' : {fl: 'far', fc: SvgIcons.faUserNinja, fixedWidth: true},
    'userNinjaSmallMedium' : {fl: 'far', fc: SvgIcons.faUserNinja, size: '3x'},
    'userCowboyFW' : {fl: 'far', fc: SvgIcons.faUserCowboy, fixedWidth: true},
    'userCowboyMedium' : {fl: 'far', fc: SvgIcons.faUserCowboy, size: '4x'},

    'videoFW' : {fl: 'far', fc: SvgIcons.faVideo, fixedWidth: true},
    'videoFWSmall' : {fl: 'far', fc: SvgIcons.faVideo, fixedWidth: true, size: '2x'},
    'videoSlashFW' : {fl: 'far', fc: SvgIcons.faVideoSlash, fixedWidth: true},
    'videoSlashFWSmall' : {fl: 'far', fc: SvgIcons.faVideoSlash, fixedWidth: true, size: '2x'},

    'wrench' : {fl: 'far', fc: SvgIcons.faWrench},

    //fab
    'androidFW' : {fl: 'fab', fc: SvgIcons.faAndroid, fixedWidth: true},
    'appleFW' : {fl: 'fab', fc: SvgIcons.faApple, fixedWidth: true},
    'chromeFW' : {fl: 'fab', fc: SvgIcons.faChrome, fixedWidth: true},
    'windowsFW' : {fl: 'fab', fc: SvgIcons.faWindows, fixedWidth: true},

    //fad
    'heart' : {fl: 'fad', fc: SvgIcons.faHeart},
    'heartFW' : {fl: 'fad', fc: SvgIcons.faHeart, fixedWidth: true},
    'paintBrushFW' : {fl: 'fad', fc: SvgIcons.faPaintBrush},
    'sortDown' : {fl: 'fad', fc: SvgIcons.faSortDown},
    'sortUp' : {fl: 'fad', fc: SvgIcons.faSortUp},
    'users' : {fl: 'fad', fc: SvgIcons.faUsers},
    'usersFW' : {fl: 'fad', fc: SvgIcons.faUsers, fixedWidth: true},
    'usersSmall' : {fl: 'fad', fc: SvgIcons.faUsers, size: '2x'},
    'userPlus' : {fl: 'fad', fc: SvgIcons.faUserPlus},
    'userPlusSmall' : {fl: 'fad', fc: SvgIcons.faUserPlus, size: '2x'},
    'userTimes' : {fl: 'fad', fc: SvgIcons.faUserXmark},
    'userTimesSmall' : {fl: 'fad', fc: SvgIcons.faUserXmark, size: '2x'},
    'userEditSmall' : {fl: 'fad', fc: SvgIcons.faUserPen, size: '2x'},
    'userFriends' : {fl: 'fad', fc: SvgIcons.faUserGroup},

    //fas
    'pause' : {fl: 'fas', fc: SvgIcons.faPause},
    'play' : {fl: 'fas', fc: SvgIcons.faPlay},
    'treeAlt' : {fl: 'fas', fc: SvgIcons.faTreeDeciduous},
    'volume' : {fl: 'fas', fc: SvgIcons.faVolume},
    'volumeMute' : {fl: 'fas', fc: SvgIcons.faVolumeXmark},

    //layered
    'cloudFasHome' : {fl: 'fas', fc: SvgIcons.faCloudFas, size: '2x', flip: 'horizontal'},
    'cloudFasEditor' : {fl: 'fas', fc: SvgIcons.faCloudFas, flip: 'horizontal', transform: 'horizontal-grow-8'},

    'cloudCheckLayeredHome': {
      sizeObj: {fontSize: '2em'},
      layerOne: {fl: 'fas', fc: SvgIcons.faCloudFas, flip: 'horizontal'},
      layerTwo: {fl: 'fas', fc: SvgIcons.faCheckFas, transform: 'shrink-9', styleObj: {color: '#fff', marginTop: '2px'}}
    },
    'cloudTimesLayeredHome': {
      sizeObj: {fontSize: '2em'},
      layerOne: {fl: 'fas', fc: SvgIcons.faCloudFas, flip: 'horizontal'},
      layerTwo: {fl: 'fas', fc: SvgIcons.faXmarkFas, transform: 'shrink-9', styleObj: {color: '#fff', marginTop: '2px'}}
    },
    'cloudCheckLayeredEditor': {
      sizeObj: {fontSize: '1em'},
      layerOne: {fl: 'fas', fc: SvgIcons.faCloudFas, flip: 'horizontal', transform: 'horizontal-grow-8'},
      layerTwo: {fl: 'fas', fc: SvgIcons.faCheckFas, transform: 'shrink-5', styleObj: {color: '#fff', marginTop: '1px'}}
    },
    'cloudTimesLayeredEditor': {
      sizeObj: {fontSize: '1em'},
      layerOne: {fl: 'fas', fc: SvgIcons.faCloudFas, flip: 'horizontal', transform:'horizontal-grow-8'},
      layerTwo: {fl: 'fas', fc: SvgIcons.faXmarkFas, transform: 'shrink-5', styleObj: {color: '#fff', marginTop: '1px'}}
    },

  };


  export default {
    name: 'ScIcon',
    props: {
      name: {type: String, default: null},
    },
    computed: {
      getIcon () {
        let key = this.name;
        return (key && _iconMap[key]) ? _iconMap[key] : _iconMap['default'];
      }
    }
  }

</script>

<style>

  @keyframes si-spin {
    0% { transform: rotate(0deg); }
    100% {  transform: rotate(359deg); }
  }
  .si-spin svg {
    animation: si-spin 2s linear infinite;
  }
  .si-size-1x svg {
    width: 1em;
    height: 1em;
  }
  .si-size-2x svg {
    width: 2em;
    height: 2em;
  }
  .si-size-3x svg {
    width: 3em;
    height: 3em;
  }
  .si-size-4x svg {
    width: 4em;
    height: 4em;
  }
  .si-size-5x svg {
    width: 5em;
    height: 5em;
  }
  .si-size-6x svg {
    width: 6em;
    height: 6em;
  }
  .si-shrink-9 svg {
    transform: scale(0.4);
  }
  .si-shrink-5 svg {
    transform: scale(0.6875) !important;
  }
  .si-horizontal-grow-8 svg {
    transform: scaleX(-1) scale(1.5) !important;
  }
  .si-fw svg {
    text-align: center;
  }
  .si-rotate-270 svg {
    transform: rotate(270deg);
  }
  .si-rotate-90 svg {
    transform: rotate(90deg);
  }
  .si-flip-horiz svg {
    transform: scaleX(-1);
  }
  .si svg {
    fill: currentColor;
    vertical-align: -.125em;
    display: inline-block;
  }
  .si-stack {
    display: inline-block;
    height: 1em;
    line-height: 1em;
    position: relative;
    vertical-align: -0.125em;
    width: 1em;
  }
  .si-stack-1x {
    line-height: inherit;
  }
  .si-stack-1x, .si-stack-2x {
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }

</style>