<template>
    <div class="notifications fixed-top">
        <div v-for="notification in notifications" :key="notification.guid"
             class="card text-white shadow-lg my-3 rounded border"
             :class="{
                'bg-danger border-danger': notification.type === 'danger',
                'bg-info border-info': notification.type === 'info',
            }"><div class="card-body p-3 d-flex justify-content-between">
            {{notification.msg}}
            <button type="button" class="btn-close my-auto" aria-label="Close"
                    @click.prevent="remove(notification.id)" style="opacity: 0.5;"></button>
        </div>
        </div>
    </div>
</template>

<script>

    import ScCommonUtil from './ScCommonUtil';

    let _ins = null;

    let ScNotification = {
        name: "ScNotification",
        data: function() {
            return {
                notifications: []
            }
        },
        created() {
            _ins = this;
        },
        beforeUnmound() {
            _ins = null;
        },
        methods: {
            removeAll: function() {
                this.notifications.splice(0);
            },
            remove: function(id) {
                this.notifications = this.notifications.filter(item => item.id !== id)
            },
            add: function(n) {
                let notification = Object.assign({
                    id: new Date().getTime(), msg: '', type: 'info', timeout: 10000
                }, n);
                this.notifications.push(notification);
                setTimeout(() => {
                    this.remove(notification.id);
                }, notification.timeout);
            }
        },

        computed: {


        },

        watch: {

        },

        /**
         * Public methods
         */

        notify: function(n) {
            //console.log('ScNotification ins', _ins, _ins.$children[0]);
            if (_ins) _ins.add(n);
        },

        clear: function() {
            //console.log('clear', this);
            if (!_ins) return;
            _ins.removeAll();
        },

        /**
         * Show a growl and log the message.
         */

        growlErrMsg: function (msg) {
            console.error('ScNotification.growlErrMsg', msg);
            ScNotification.notify({msg: String(msg), type: 'danger', timeout: 10000});
        },

        growlSuccessMsg: function (msg) {
            ScNotification.notify({msg: String(msg), type: 'info', timeout: 5000});
        },

        growlClear: function () {
            ScNotification.clear();
        },

        // deprecated: use axios and growlAxiosError
        growlXhrError: function(jqXhr, actionText) {
            let msg = ScCommonUtil.httpCodeToText(jqXhr.status) + ' error while ' + actionText + '. ';
            if (jqXhr.responseJSON && jqXhr.responseJSON.message) {
                msg = jqXhr.responseJSON.message + ' (' + actionText + ')';
            }
            ScNotification.growlErrMsg(msg);
        },

        growlAxiosError: function(axiosError, actionText) {
            console.log('growlAxiosError', axiosError, axiosError.response);
            let statusDesc = axiosError.response ? ScCommonUtil.httpCodeToText(axiosError.response.status) : 'Unknown';
            let msg = statusDesc + ' error while ' + actionText + '. ';
            if (axiosError.response.data && axiosError.response.data.message) {
                msg = axiosError.response.data.message + ' (' + actionText + ')';
            }
            ScNotification.growlErrMsg(msg);
        },

        /**
         * Show a growl then reject the promise.
         */
        growlReject: function (reject) {
            return function (e) {
                if (!e) e = 'No error object could be found.';
                console.error('growlReject', reject);
                ScNotification.notify({msg: 'Rejection: ' + e, type: 'danger', delay: 10000});  // todo: remove this line
                reject(e);
            }
        },

    };

    export default ScNotification;

</script>

<style scoped>

    .notifications {
        top: 20px;
        left: calc(50% - 155px);
        width: 310px;
        z-index: 11007002; /* see README.md webapp z-index values */
    }

</style>