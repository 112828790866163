
import { createApp } from 'vue'
import { createVbPlugin } from 'vue3-plugin-bootstrap5'
import { Modal, Toast, Tooltip } from 'bootstrap'

import App from './App.vue';
import router from './router';
import store from './store';
import StoreUtils from "./store/StoreUtils";
import ScVueTrackEvents from './components/global/ScVueTrackEvents';
import ScBootstrapUtils from "./components/global/ScBootstrapUtils";

StoreUtils.watchAjaxCalls(store)

router.store = store
const app = createApp(App)

ScBootstrapUtils.initScTooltip(app);
ScVueTrackEvents.init(app);
const vbPlugin = createVbPlugin({ Modal, Toast, Tooltip }, { vbModalBaseZindex: 11006002 })

app.config.unwrapInjectedRef = true // can be removed after upgrade to vue 3.3.x

app.use(router)
app.use(store)
app.use(vbPlugin)

app.mount('#app')
