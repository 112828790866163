

import {Dropdown, Modal } from 'bootstrap';

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default {

    initScTooltip(app) {
        /**
         * use:
         *     v-sc-tooltip="'Help goes here'"
         * or:
         *     v-sc-tooltip="someJs ? : 'Help me' : 'No way'"
         * in any Vue managed element for tooltips
         */

        app.directive('sc-tooltip', {
            mounted: function(el, binding){
                //console.log('sc-tooltip inserted', el, binding);
                tippy(el, {content: binding.value, delay: 250, zIndex: 30000010});  /* see README.md webapp z-index values */
            },
            updated: function(el, binding) {
                //console.log('sc-tooltip update', el, binding);
                if (el._tippy) el._tippy.setContent(binding.value);
            },
            unmounted: function(el) {
                //console.log('sc-tooltip unbind', el);
                if (el._tippy) el._tippy.destroy();
            },
        });

    },

    ensureDropdownShown(toggleEl) {
        setTimeout(() => {
            let dd = Dropdown.getInstance(toggleEl);
            if (!dd) dd = new Dropdown(toggleEl);
            //console.log('ensureDropdownShown', toggleEl, dd);
            if (dd) dd.show();
        }, 1);
    },

    ensureDropdownHidden(toggleEl) {
        setTimeout(() => {
            let dd = Dropdown.getInstance(toggleEl);
            //console.log('ensureDropdownHidden', toggleEl, dd);
            if (dd) dd.hide();
        }, 1);
    },

    modalHideAll() {
        //console.log('modalHideAll');
        let modals = document.getElementsByClassName('modal');
        if (modals && modals.length > 0) {
            modals.forEach((modalEl) => {
                let modal = Modal.getInstance(modalEl);
                if (modal) modal.hide();
            });
        }
    },

}