

import $ from 'jquery'
import ScCommonUtil from '../../shared/common/ScCommonUtil'

export default {

    init: function(app) {
        app.directive('sc-trackevent', {
            mounted: function(el, binding) {
                el.addEventListener('click', () => {
                    //console.log('click event track', binding.value);
                    let val = binding.value;
                    if (val && val.indexOf(':') > 1) {
                        _trackEvent(val.substr(0, val.indexOf(':')),
                          val.substr(val.indexOf(':')+1, val.length), null);
                    }
                }, false);
            }
        });
    },

    trackPageView: function(to) {
        _trackEvent(to.name, 'view', null);
    },

    trackSignupPageView: function() {
        _trackEvent('signup', 'view', null);
    },

    trackSignupEvent: function(eventCategory, eventAction) {
        _trackEvent('signup', eventCategory, eventAction);
    },

    trackLoginSuccess: function() {
        _trackEvent('signin', 'signedin', null);
    },

    trackViewerScreenView: function(screenName) {
        _trackEvent(screenName, 'view', null);
    },

    trackViewerEvent: function(eventCategory, eventAction, eventLabel) {
        _trackEvent(eventCategory, eventAction, eventLabel);
    },

    trackVideoEvent: function(workshopId, resourceId, pageId, sharedId, durationSeconds) {
        _trackVideoEvent(workshopId, resourceId, pageId, sharedId, durationSeconds);
    }
}

let _sessionUuid = ScCommonUtil.guid();


let _trackEvent = function(screenName, usageEventCategory, usageEventName) {
    //console.log('_trackEvent', screenName, usageEventCategory, usageEventName);
    let language = null;
    if (window.navigator.language && window.navigator.language.length > 0) language = window.navigator.language;
    let obj = {
        client_name: 'browser', client_version: window.SC_VERSION, client_event_uuid: ScCommonUtil.guid(),
        client_session_uuid: _sessionUuid,
        usage_date_ms: Date.now(), usage_screen_name: screenName, usage_event_category: usageEventCategory,
        usage_event_name: usageEventName,
        device_screen_size: window.screen.width + 'x' + window.screen.height,
        device_viewport: window.innerWidth + 'x' + window.innerHeight,
        user_language: language
    };
    $.ajax({type: "POST", url: '/main/usage/collect_usage_v1', data: JSON.stringify({usage_data: obj})});
    // ignore errors etc from ajax post!
};

let _trackVideoEvent = function(workshopId, resourceId, pageId, sharedId, durationSeconds) {
    let language = null;
    if (window.navigator.language && window.navigator.language.length > 0) language = window.navigator.language;

    let obj = {
        client_name: 'browser',
        client_version: window.SC_VERSION,
        client_event_uuid: ScCommonUtil.guid(),
        client_session_uuid: _sessionUuid,
        device_screen_size: window.screen.width + 'x' + window.screen.height,
        device_viewport: window.innerWidth + 'x' + window.innerHeight,
        user_language: language,
        user_timezone: new Date().getTimezoneOffset(),
        workshop_id: workshopId,
        resource_id: resourceId,
        page_id: pageId,
        shared_id: sharedId,
        duration_seconds: durationSeconds,
        event_timestamp_ms: (new Date()).getTime()
    };
    $.ajax({type: "POST", dataType: "json", contentType: "application/json; charset=UTF-8", url: '/api/analytics_video_web_v1', data: JSON.stringify(obj)});
}
