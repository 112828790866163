

import _ from 'underscore'; // wrap, memoize, debounce
import $ from "jquery";

let timePageLoaded = new Date().getTime();
let timeLastCheckedWorkshop = new Date().getTime();

export default {

    /*
        We need to debounce the ajax call for each hotspot.  This tricky function will do it for us.
        See: https://stackoverflow.com/questions/28787436/debounce-a-function-with-argument
    */
    debounceBasedOnArgId: function(dataManagerFunctionToCall, debounceDelayMs) {
        let functionToCall = function(a1, a2) {
            dataManagerFunctionToCall(a1, a2).then(() => {
                // worked ok
            }, () => {
                // did not work, error handled in dataManagerFunctionToCall directly
            });
        };
        let getId = (o) => {
            return o.id;
        }
        return _.wrap(
            _.memoize(function () {
                return _.debounce(functionToCall, debounceDelayMs);
            }, getId), function (func, obj, a1, a2) {
                return func(obj, a1, a2)(obj, a1, a2);
            });
    },

    collectAndDelayHotspotChanges: function(dataManagerFn, debounceDelayMs) {
        // when editing is occuring across many hostpos quickly we only want to send one ajax call every few seconds
        // with a single batch of changes
        // here we collect up all the changes

        // Similar to _.debounce when a sequence of calls of the returned function ends, the function is triggered.

        let previousStartMs = 0;
        let hotspotsPending = [];
        let delayTimeout = null;

        return function(hotspotArr, storeCtx) {
            if (delayTimeout) clearTimeout(delayTimeout);

            let changedHsIds = hotspotArr.map((hs) => hs.id);  // get list of all ids in this batch

            // remove hotspots from pending list we have in this new batch
            hotspotsPending = hotspotsPending.filter((hs) => !changedHsIds.includes(hs.id));
            hotspotsPending.push(...hotspotArr);

            // when the delay is over we pass all pending hotspot changes to the dataManagerFn
            let afterDelayFn = function() {
                previousStartMs = 0;
                let hotspotsToSend = [...hotspotsPending];
                hotspotsPending.splice(0);
                //console.log('hotspotsToSend', hotspotsToSend.map((hs) => hs.id).join(','));
                dataManagerFn(hotspotsToSend, storeCtx).then(() => {
                    // worked ok
                }, () => {
                    // did not work, error handled in dataManagerFunctionToCall directly
                });
            }
            // calculate delay to the first time we were invoked within the debounceDelayMs window
            let passedMs = Date.now() - previousStartMs;
            let delayMs = debounceDelayMs;
            if (delayMs > passedMs) delayMs = debounceDelayMs - passedMs;
            if (previousStartMs === 0) previousStartMs = Date.now();

            delayTimeout = setTimeout(afterDelayFn, delayMs);
        }
    },

    /**
     * Delete keys so that Vue watchers can 'see' what is happening.
     */
    vueDeleteAllKeys: function(obj) {
        //console.log('vueDeleteAllKeys', obj);
        Object.keys(obj).forEach(function(key) {
            delete obj[key]
        });
    },

    /**
     * Iterate over keys in a source object then set based on a template copy them to a destination obj.
     */
    vueSetAllKeys: function(objDest, objSrc, objTemplate) {
        //console.log("cloneExtendOwnAllKeys", objDest, objSrc, objTemplate);
        Object.keys(objSrc).forEach(function(key) {
            objDest[key] = Object.assign({}, objTemplate, objSrc[key]);  // copy properties in src over template onto a fresh obj
        });
    },

    watchAjaxCalls: function(store) {
        $(document).bind('ajaxSend', function(elm, xhr, s){  // add header for all ajax calls
            //console.log('ajaxSend', window.SC_FORM_KEY);
            if (s.type === 'POST' && window.SC_FORM_KEY && typeof window.SC_FORM_KEY === 'string') {
                xhr.setRequestHeader('scformkey', window.SC_FORM_KEY);
            }

        }).bind('ajaxError', function(e, e2) {  // track ajax failures
            console.log('global ajaxError', e2.status, e2.readyState, e2.statusText);
            if (e2.status === 0 || e2.readyState === 0) {
                return;  // If either of these are true, then it's not a true error and we don't care
            }
            if ( ('' + e2.status) === '401') {  // trap session time-outs and trigger the timeout modal
                setTimeout(() => {
                    //console.log('got a 401', timePageLoaded < (new Date().getTime() - (1000 * 30)));
                    // page loaded more than 1 min ago, we check this to prevent loop on the initial page load
                    if (timePageLoaded < (new Date().getTime() - (1000 * 30))) {
                        store.commit('userSessionExpiredModalToggle', true);
                    }
                }, 1000);
            }
            if ( ('' + e2.status) === '403') {  // this may be a sign the user is not in the right workshop
                if (timeLastCheckedWorkshop < (new Date().getTime() - (1000 * 60))) {
                    timeLastCheckedWorkshop = new Date().getTime();
                    store.dispatch('userPrefsLoad');  // reload the user prefs (workshop list incl)
                }
            }
        });

        // record the timezone offset so the server can adjust dates, this is sent with all requests
        document.cookie = 'tz=' + (new Date().getTimezoneOffset()/60) + ';path=/';
    },

}