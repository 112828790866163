<template>
    <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" v-vb-is:modal.show ref="scModal"
         @vb-hidden-bs-modal="modalWasHidden" @keydown.enter="handleEnterKeyDown"
            class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content shadow-lg">
                <div class="modal-header">
                    <h4 class="modal-title w-100 text-center text-break">{{message}}</h4>
                </div>
                <div class="modal-footer">
                    <a href="#" class="btn btn-link" data-bs-dismiss="modal">Cancel</a>
                    <a href="#" class="btn btn-primary-orange fw-bold" @click.prevent="returnTrue">Ok</a>
                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>


<script>

    let _defaultMessage = 'Are you sure?';
    let _ins = null;

    export default {
        name: 'ScConfirmModal2',
        data () {
            return {
                isModalShown: false,
                message: _defaultMessage,
                returnBool: false,
                resolvePromise: null,
                rejectPromise: null
            };
        },
        mounted () {
            _ins = this;
        },
        beforeUnmount: function() {
            _ins = null;
        },
        methods: {
            handleEnterKeyDown(e) {
                // note, escape handled by bootstrap
                if (this.isModalShown) {
                    e.preventDefault();
                    this.returnTrue();
                }
            },
            modalWasHidden() {
                this.isModalShown = false;
                if (this.returnBool && this.resolvePromise) this.resolvePromise();
                if (!this.returnBool && this.rejectPromise) this.rejectPromise();
            },
            hideModal() {
                this.$refs.scModal.$vb.modal.hide();
            },
            returnTrue() {
                this.returnBool = true;
                this.hideModal();
                // now vb-hidden-bs-modal is triggered, then modalWasHidden called
            },
            toggleBsModal(message) {
                return new Promise((resolve, reject) => {
                    this.returnBool = false;
                    this.message = _defaultMessage;
                    if (message) this.message = message;
                    this.resolvePromise = resolve;
                    this.rejectPromise = reject;
                    this.isModalShown = true;
                });
            },
        },

        //
        // public functions
        //
        modal (message) {
            return _ins.toggleBsModal(message);
        }

    }

</script>

