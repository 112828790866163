
import { createStore } from 'vuex'

// modules are registered by the components that use them (eg, EditorMain)

export default createStore({
    // Making sure that we're doing everything correctly by enabling strict mode in the dev environment.
    // update: jun 2021 strict mode too slow with large data sets: https://github.com/vuejs/vuex/issues/659
    strict: false, // process.env.NODE_ENV !== 'production',
});
