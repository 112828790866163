

import ScNotification from '../shared/common/ScNotification.vue';
import $ from 'jquery';
import {isAfter} from 'date-fns';
import ScCommonUtil from '../shared/common/ScCommonUtil';

let _state = {
    prefsLoaded: false,
    workshops: [],
    likes: [],
    currentWorkshopId: null,
    showUserGuide: false,
    showSessionExpiredModal: false,
    user_id: null,
    displayable_name: null,
    email: null,
    user_auth_type: null,
    file_picker_conf: null,
    pn_sub_key: null,
    pubSubKey: null,
    pubSubDomain: null,
    hide_subscription_alert_until: 0,
    termsAccepted: false,

    home_order_by: 'id_desc', // id_desc, id_asc, name_asc
    presentation_order_by: 'id_desc', // id_desc, id_asc, name_asc
    library_view_style: 'list',  // grid, list
    library_order_by: 'name_asc',  // name_asc, id_desc, file_name_asc, file_id_desc

    viewer_intro_video_disabled: [],
    viewer_kiosk_mode: false,
};

let _templateWorkshop = {
    id: null,
    can_import_doc_in_apps: false,
    expired: false,
    expiry_date: null,
    is_trial_plan: true,
    last_used: '1970-01-01T00:00:00.000000Z',
    name: null,
    pubnub_workshop_channel_key: null,
    shared_expiry_days: 1,
    ws_actions: [],
    is_crm_disabled: true,
    weburl_user_jwt: null,
    header_img: null
};

let _templateLike = {
    id: null,
    workspace_id: null,
    showcase_id: null,
    page_id: null,
    resource_id: null
}


let _getters = {
    // should always start with "user" to avoid collisions with other modules

    userCurrentWorkspaceObj: (state) => {
        if (!state.currentWorkshopId || !state.workshops) return null;
        return Object.values(state.workshops).find(ws => ws.id === state.currentWorkshopId);
    },

    userLikesForWorkspace: (state) => (workspaceId) => {
        if (!state.likes) return [];
        return state.likes.filter(likeObj => likeObj.workspace_id === workspaceId);
    },

    userCanPerform: (state) => (requiredAction, workspaceId) => {
        //console.log('userCanPerform', requiredAction, workspaceId);
        if (!workspaceId || !state.workshops) return false;
        let currentWs = Object.values(state.workshops).find(ws => ws.id === workspaceId);
        if (!currentWs || !currentWs.ws_actions) return false;
        return currentWs.ws_actions.indexOf(requiredAction) > -1;
    },

    userGetMostRecentWorkspaceObj: (state) => {
        //console.log('userGetMostRecentWorkspaceObj');
        if ( !state.workshops || state.workshops.length === 0 || !state.workshops[0] ) {
            return null;
        }
        let mostRecentlyUsedWorkspaceLastUsed = state.workshops[0].last_used;
        if ( ! mostRecentlyUsedWorkspaceLastUsed ) {
            mostRecentlyUsedWorkspaceLastUsed = '1970-01-01T00:00:00.000000Z';
        }
        let mostRecentlyUsedWorkspace = state.workshops[0];
        state.workshops.forEach((ws) => {
            if ( ws.last_used && isAfter(new Date(ws.last_used), new Date(mostRecentlyUsedWorkspaceLastUsed))) {
                mostRecentlyUsedWorkspaceLastUsed = ws.last_used;
                mostRecentlyUsedWorkspace = ws;
            }
        });
        //console.log('userGetMostRecentWorkspaceObj found', mostRecentlyUsedWorkspace);
        return mostRecentlyUsedWorkspace;
    },

    userGetUserId: (state) => () => {
        return state.user_id;
    }

};


let _actions = {
    // should always start with "user" to avoid collisions with other modules
    userPrefsLoad: function(context) {
        return new Promise((resolve) => {
            //console.log('store/webapp-user-prefs/actions/userPrefsLoad', context);
            // sc.workshopId, sc.showcaseId)
            $.ajax({
                method: "GET", url: '/main/api/v1/user-prefs/',
            }).then(function(data){
                context.commit('userPrefsLoaded', data);
                resolve();
            }).catch(function(jqXhr){
                //console.log('could not load user prefs', jqXhr);
                context.commit('userPrefsLoaded', null);
                resolve();
                if (jqXhr.status !== 401) {  // expect a 401 when the user is signed out
                    ScNotification.growlXhrError(jqXhr, 'fetching preferences');
                }
            });
        });
    },

    userSaveLibPrefs (context, payload) {
        $.ajax({
            type: 'POST', url: '/main/library/ajax_library_user_prefs',
            data: {
                workspace_id: context.state.currentWorkshopId,
                library_order_by: payload.sortOrder,
                library_view_style: payload.viewStyle
            }
        }).fail(function (jqXhr) {
            ScNotification.growlXhrError(jqXhr, 'saving library preferences.');
        });
        context.commit('userUpdateLibPrefs', payload);
    },

    userSaveHomePrefs (context, payload) {
        $.ajax({
            type: 'POST', url: '/main/presentations/ajax_home_user_prefs',
            data: {
                workspace_id: context.state.currentWorkshopId,
                home_order_by: payload.sortOrder
            }
        }).fail(function (jqXhr) {
            ScNotification.growlXhrError(jqXhr, 'saving home preferences');
        });
        context.commit('userUpdateHomePrefs', payload);
    },

    userSavePresOrderPrefs (context, payload) {
        $.ajax({
            type: 'POST', url: '/main/presentations/ajax_presentation_order_user_prefs',
            data: {
                workspace_id: context.state.currentWorkshopId,
                presentation_order_by: payload.sortOrder
            }
        }).fail(function (jqXhr) {
            ScNotification.growlXhrError(jqXhr, 'saving presentation order preferences');
        });
        context.commit('userUpdatePresOrderPrefs', payload);
    },

    userLoginPost(context, payload) {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'post', url: '/main/default/ajax_login',
                data: {email: payload.email, pwd: payload.pwd}
            }).then((data) => {
                if (data.status === 'ok') {
                    resolve();
                } else {
                    ScNotification.growlErrMsg('Oops, we had an error' + (data.message ? ': ' + data.message : ''));
                    reject();
                }

            }).fail((jqXhr) => {
                if (jqXhr.status && jqXhr.status === 403) {  // page needs to be reloaded scformkey possibly invalid
                    ScNotification.growlErrMsg('Submission error when trying to login, reloading...');
                    reject();
                    setTimeout(() => {
                        document.location.reload();
                    }, 1000);
                } else {
                    ScNotification.growlXhrError(jqXhr,'trying to login');
                    reject();
                }
            });
        });
    },

    userLogout(context) {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST', url: '/main/default/ajax_logout'
            }).then(() => {
                context.commit('userPrefsLoaded', null);
                resolve();
            }).fail((jqXhr) => {
                ScNotification.growlXhrError(jqXhr, 'logging out');
                reject();
            });
        });
    },

    userLoginValidateEmail(context, payload) {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'post', url: '/main/default/ajax_email_validate',
                data: {email: payload.email, client_name: 'browser', send_signup_code: payload.sendCode }
            }).then((data) => {
                if (data.status === 'ok') {
                    resolve({
                        'user_exists': data.user_exists, 'signup_code_error': data.signup_code_error,
                        'invite_pending': data.invite_pending,
                        'login_requirements_type': data.login_requirements_type,  // oauth_force, oauth_optional
                        'login_requirements_url': data.login_requirements_url,   // null or a url for the oauth url
                        'login_requirements_silent_url': data.login_requirements_silent_url,   // null or a url for the silent oauth url
                        'prevent_signup_message': data.prevent_signup_message   // null or message
                    });
                } else {
                    ScNotification.growlErrMsg('Oops, we had an error' + (data.message ? ': ' + data.message : ''));
                    reject();
                }

            }).fail((jqXhr) => {
                if (jqXhr.status && jqXhr.status === 403) {  // page needs to be reloaded scformkey possibly invalid
                    ScNotification.growlErrMsg('Submission error when trying to login, reloading...');
                    reject();
                    setTimeout(() => {
                        document.location.reload();
                    }, 1000);
                } else {
                    ScNotification.growlXhrError(jqXhr, 'verifying email');
                    reject();
                }
            });
        });
    },

    userValidateAuthToken(context, payload) {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'post', url: '/main/default_oauth2/ajax_validate_state_browser',
                data: {osk: payload.osk}
            }).then((data) => {
                if (data.status === 'ok') {
                    resolve();
                } else {
                    reject('Oops, we had an error' + (data.message ? ': ' + data.message : ''));
                }
            }).fail((jqXhr) => {
                reject(ScCommonUtil.httpCodeToText(jqXhr.status) + ' error when trying to validate token');
            });
        });
    },

    userRefreshAuthToken(context, payload) {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'post', url: '/main/default_oauth2/ajax_refresh_state_browser',
                data: {osk: payload.osk}
            }).then((data) => {
                if (data.status === 'ok') {
                    resolve();
                } else {
                    reject('Oops, we had an error' + (data.message ? ': ' + data.message : ''));
                }
            }).fail((jqXhr) => {
                reject(ScCommonUtil.httpCodeToText(jqXhr.status) + ' error when trying to validate token');
            });
        });
    },

};


let _mutations = {
    // should always start with "user" to avoid collisions with other modules
    userPrefsLoaded: function(state, userPrefs) {
        //console.log('userPrefsLoaded', userPrefs);
        state.workshops.splice(0);
        state.likes.splice(0);

        if (userPrefs) {
            if (userPrefs.workshops) {
                userPrefs.workshops.forEach((ws) => {
                    state.workshops.push(Object.assign({}, _templateWorkshop, ws));
                });
            }
            if (userPrefs.likes) {
                userPrefs.likes.forEach((like) => {
                    state.likes.push(Object.assign({}, _templateLike, like));
                });
            }

            state.user_id = userPrefs.user_id;
            state.user_auth_type = userPrefs.auth_type;
            state.email = userPrefs.email;
            state.displayable_name = userPrefs.displayable_name;
            // state.email_sharing_activity = userPrefs.
            state.file_picker_conf = userPrefs.file_picker_conf;
            // state.full_name = userPrefs.
            state.pn_sub_key = userPrefs.pn_sub_key;
            state.pubSubKey = userPrefs.pub_sub_key;
            state.pubSubDomain = userPrefs.pub_sub_domain;
            state.termsAccepted = userPrefs.terms_accepted;
            state.showSessionExpiredModal = false;
            if (userPrefs.home_order_by) state.home_order_by = userPrefs.home_order_by;
            if (userPrefs.presentation_order_by) state.presentation_order_by = userPrefs.presentation_order_by;
            if (userPrefs.library_order_by ) state.library_order_by = userPrefs.library_order_by;
            if (userPrefs.library_view_style) state.library_view_style = userPrefs.library_view_style;
        }   else {
            state.user_id = null;
            state.user_auth_type = null;
            state.email = null;
            state.displayable_name = null;
            state.currentWorkshopId = null;
            state.pn_sub_key = null;
            state.pubSubKey = null;
            state.pubSubDomain = null;
            state.termsAccepted = false;
        }

        if (state.currentWorkshopId) {  // validate we have a valid currentWorkshopId
            let currentWs = Object.values(state.workshops).find(ws => ws.id === state.currentWorkshopId);
            if (!currentWs) state.currentWorkshopId = null;
        }
        state.prefsLoaded = true;
    },

    userWorkshopChange: function(state, workshopId) {
        //console.log('userWorkshopChange from WebappUserPrefsStore', workshopId, state.workshops);
        let currentWs = null;
        if (workshopId) {
            currentWs = Object.values(state.workshops).find(ws => ws.id === workshopId);
            state.currentWorkshopId = currentWs ? workshopId : null;
        }
        if (!state.currentWorkshopId && state.workshops.length > 0) {
            currentWs = _getters.userGetMostRecentWorkspaceObj(state);
            if (currentWs) state.currentWorkshopId = currentWs.id;
        }
        if (currentWs) currentWs.last_used = new Date();
    },

    userUpdateLibPrefs: function(state, payload) {
        state.library_order_by = payload.sortOrder;
        state.library_view_style = payload.viewStyle;
    },

    userUpdateHomePrefs: function(state, payload) {
        state.home_order_by = payload.sortOrder;
    },

    userUpdatePresOrderPrefs: function(state, payload) {
        state.presentation_order_by = payload.sortOrder;
    },

    userUpdateDetails: function(state, payload) {
        state.email = payload.email;
        state.displayable_name = payload.displayable_name;
    },

    userUpdateHeaderImg: function(state, payload) {
        if (state.currentWorkshopId) {
            let currentWs = Object.values(state.workshops).find(ws => ws.id === state.currentWorkshopId);
            currentWs.header_img = (payload.header_url) ? payload.header_url: null;
        }
    },

    userUpdateLikes: function(state, payload) {
        if (payload.like_bool) {
            state.likes.push({
                'id': Number(payload.id),
                'page_id': Number(payload.pageId),
                'resource_id': (payload.resourceId) ? Number(payload.resourceId) : null,
                'showcase_id': Number(payload.showcaseId),
                'workspace_id': Number(payload.workspaceId)
            });
        } else {
            state.likes = state.likes.filter(likeObj => likeObj.id !== payload.id);
        }
    },

    userShowGuide: function(state, showGuide) {
        state.showUserGuide = showGuide;
    },

    userTermsAccepted: function(state, termsAcceptedBool) {
        state.termsAccepted = termsAcceptedBool;
    },

    userViewerIntroVideoToggle: function(state, presId) {
        //console.log('intro video add pres', presId);
        if (state.viewer_intro_video_disabled.includes(presId)) {
          state.viewer_intro_video_disabled = state.viewer_intro_video_disabled.filter(p => p.id === presId);
        }
        else {
          state.viewer_intro_video_disabled.push(presId);
        }
    },

    userViewerKioskModeToggle: function(state, isEnabled) {
        state.viewer_kiosk_mode = isEnabled;
    },

    userWorkspaceShareableOnSlideByDefault: function(state, isEnabled) {
        if (state.currentWorkshopId) {
            let currentWs = Object.values(state.workshops).find(ws => ws.id === state.currentWorkshopId);
            currentWs.shareable_on_slide_by_default = isEnabled;
        }
    },

    userSessionExpiredModalToggle: function(state, isShown) {
        state.showSessionExpiredModal = isShown;
    },

    userExtendSubscriptionAlert: function(state) {
        state.hide_subscription_alert_until = new Date().getTime() + (1000 * 60 * 60 * 24);
    }

};


export default {
    state: _state,
    getters: _getters,
    actions: _actions,
    mutations: _mutations
};
