
import { createRouter, createWebHistory } from 'vue-router';
import ScViewTrackEvents from "./components/global/ScVueTrackEvents";

// default bundle
const SignupForm = () => import(/* webpackChunkName: "viewer" */ './components/account/SignupForm.vue');
const ResetPasswordForm = () => import(/* webpackChunkName: "viewer" */ './components/account/ResetPasswordForm.vue');
const ResetPasswordFormV2 = () => import(/* webpackChunkName: "viewer" */ './components/account/ResetPasswordFormV2.vue');
const SetNewPasswordForm = () => import(/* webpackChunkName: "viewer" */ './components/account/SetNewPasswordForm.vue');
const WorkshopDefault = () => import(/* webpackChunkName: "viewer" */ './components/account/WorkshopDefault.vue');
const AppLinkChoose = () => import(/* webpackChunkName: "viewer" */ './components/account/AppLinkChoose.vue');

// user management bundle
const UserAddList = () => import(/* webpackChunkName: "users" */ './components/users/UserAddList.vue');
const UserList = () => import(/* webpackChunkName: "users" */ './components/users/UserList.vue');
const UserView = () => import(/* webpackChunkName: "users" */ './components/users/UserView.vue');
const UserGroupList = () => import(/* webpackChunkName: "users" */ './components/users/UserGroupList.vue');

// settings bundle
const SettingsBranding = () => import(/* webpackChunkName: "settings" */ './components/settings/SettingsBranding.vue');
const SettingsWorkshop = () => import(/* webpackChunkName: "settings" */ './components/settings/SettingsWorkshop.vue');
const SettingsBilling = () => import(/* webpackChunkName: "settings" */ './components/settings/SettingsBilling.vue');
const SettingsBillingSuccess = () => import(/* webpackChunkName: "settings" */ './components/settings/SettingsBillingSuccess.vue');

// sharing bundle
const ShareContent = () => import(/* webpackChunkName: "viewer" */ './components/sharing/ShareContent.vue');

// editor
const EditorMain = () => import(/* webpackChunkName: "editor" */ './components/editor/EditorMain.vue');

// presentations
const WorkshopHome = () => import(/* webpackChunkName: "presentation" */ './components/WorkshopHome.vue');
const NewPresentation = () => import(/* webpackChunkName: "presentation" */ './components/new-presentation/NewPresentation.vue');
const Outline = () => import(/* webpackChunkName: "presentation" */ './components/presentation/outline/Outline.vue');
const EmailViewers = () => import(/* webpackChunkName: "presentation" */ './components/presentation/EmailViewers.vue');
const LibraryPage = () => import(/* webpackChunkName: "presentation" */ './components/library/LibraryPage.vue');

// viewer
const BrowserViewerList = () => import(/* webpackChunkName: "viewer" */ './components/browser-viewer/BrowserViewerList.vue');
const BrowserViewerPresentation = () => import(/* webpackChunkName: "viewer" */ './components/browser-viewer/BrowserViewerPresentation.vue');
const SearchAllPage = () => import(/* webpackChunkName: "viewer" */ './components/browser-viewer/SearchAllPage.vue');

// reporting
const Analytics = () => import(/* webpackChunkName: "reporting" */ './components/reporting/analytics/Analytics.vue');
const Activity = () => import(/* webpackChunkName: "reporting" */ './components/reporting/Activity.vue');
const SharingHistory = () => import(/* webpackChunkName: "reporting" */ './components/reporting/SharingHistory.vue');
const Form = () => import(/* webpackChunkName: "reporting" */ './components/reporting/Form.vue');

// sharee
const ViewShared = () => import(/* webpackChunkName: "viewer" */ './components/sharing/ViewShared.vue');

// debugging
const DebugPresentation = () => import(/* webpackChunkName: "debug" */ './components/debug/DebugPresentation.vue');
const DebugFileUsages = () => import(/* webpackChunkName: "debug" */ './components/debug/DebugFileUsages.vue');

// not found
const ScNotFound = () => import(/* webpackChunkName: "notfound" */ './components/global/ScNotFound.vue');


const routes = [
    /* redirects etc */
    { name: 'notfound', path: '/:pathMatch(.*)*', component: ScNotFound,
        meta: {needsAuth: false }},
    { name: 'none', path: '/main/presentations/list', component: null,
        meta: {needsAuth: false }}, /* prevent endless loop */

    /* account bundle */
    { name: 'reset', path: '/workshop/reset', component: ResetPasswordForm,
            meta: {needsAuth: false, title: 'Reset Password' }},
    { name: 'reset_v2', path: '/workshop/reset-v2', component: ResetPasswordFormV2,
        meta: {needsAuth: false, title: 'Forgot Password' }},
    { name: 'set-pwd', path: '/workshop/set-pwd/:pwdKey', component: SetNewPasswordForm,
            meta: {needsAuth: false, title: 'Set new Password' }},
    { name: 'signup', path: '/workshop/signup', component: SignupForm,
        meta: {needsAuth: false, title: 'Signup' }},
    { name: 'workshop-default', path: '/workshop/default', component: WorkshopDefault,
        meta: {needsAuth: true}},
    { name: 'workshop-home', path: '/workshop/:workshopId/', component: WorkshopHome,
        meta: {needsAuth: true, neededWsAction: 'a:list_pres_for_edit', scRemountKey: 'scwshome'},
        children: [{
            name: 'workshop-home-preview', path: '/workshop/:workshopId/showcase/:showcaseId/preview',
            meta: {needsAuth: true, neededWsAction: 'a:list_pres_for_edit', title: 'Preview',
                scRemountKey: 'scwshome', scParentRouteName: 'workshop-home'},
            component: BrowserViewerPresentation, props: { useDraft: true }}]
    },
    { name: 'new-presentation', path: '/workshop/:workshopId/new', component: NewPresentation,
        meta: {needsAuth: true, neededWsAction: 'a:list_pres_for_edit'}},
    { name: 'app-link-choose', path: '/main/app-link', component: AppLinkChoose,  /* see also main/function/routes.py */
        meta: {needsAuth: false, title: 'App Link' }},
    { name: 'link-chooseapp-', path: '/main/app-link/:workshopId/showcase/:showcaseId', component: AppLinkChoose,
        meta: {needsAuth: false, title: 'App Link' }},

    /* user management bundle */
    { name: 'user-add', path: '/workshop/:workshopId/user-add', component: UserAddList,
        meta: {needsAuth: true, neededWsAction: 'a:list_users', title: 'Add Users'}},
    { name: 'user-list', path: '/workshop/:workshopId/users', component: UserList,
        meta: {needsAuth: true, neededWsAction: 'a:list_users', title: 'Users'}},
    { name: 'user-view', path: '/workshop/:workshopId/users/:userId', component: UserView,
        meta: {needsAuth: true, neededWsAction: 'a:list_users', title: 'User Details'}},
    { name: 'user-list-unassigned', path: '/workshop/:workshopId/users/-1', component: UserList,
        meta: {needsAuth: true, neededWsAction: 'a:list_users', title: 'User Details'}},

    { name: 'user-group-list', path: '/workshop/:workshopId/groups', component: UserGroupList,
        meta: {needsAuth: true, neededWsAction: 'a:list_users', title: 'Groups'}},
    { name: 'user-group-user-list', path: '/workshop/:workshopId/groups/:groupId', component: UserList,
        meta: {needsAuth: true, neededWsAction: 'a:list_users', title: 'Group Details'}},

    /* settings bundle */
    { name: 'settings-branding', path: '/workshop/:workshopId/settings-email', component: SettingsBranding,
        meta: {needsAuth: true, neededWsAction: 'a:list_branding_settings', title: 'Email Settings'}},
    { name: 'settings-workshop', path: '/workshop/:workshopId/settings-workshop', component: SettingsWorkshop,
        meta: {needsAuth: true, neededWsAction: 'a:list_workspace_settings', title: 'Workshop Settings'}},
    { name: 'settings-billing', path: '/workshop/:workshopId/settings-billing', component: SettingsBilling,
        meta: {needsAuth: true, neededWsAction: 'a:list_billing_settings', title: 'Billing Settings'}},
    { name: 'settings-billing-success', path: '/workshop/:workshopId/settings-billing-success', component: SettingsBillingSuccess,
        meta: {needsAuth: true, neededWsAction: 'a:list_billing_settings', title: 'Credit Card Setup Success'}},

    /* sharing bundle */
    { name: 'share-content', path: '/workshop/:workshopId/share-content', component: ShareContent,
        meta: {needsAuth: true, neededWsAction: 'a:list_pres_for_view', title: 'Sharing'}},

    /* editor bundle */
    { name: 'editor', path: '/workshop/:workshopId/showcase/:showcaseId/edit', component: EditorMain,
        meta: {needsAuth: true, neededWsAction: 'a:list_pres_for_edit', title: 'Edit', scRemountKey: 'sceditor'},
        children: [{
            name: 'editor-preview', path: '/workshop/:workshopId/showcase/:showcaseId/preview',
            meta: {needsAuth: true, neededWsAction: 'a:list_pres_for_edit', title: 'Preview',
                scRemountKey: 'sceditor', scParentRouteName: 'editor'},
            component: BrowserViewerPresentation, props: { useDraft: true }}]},

    /* presentations bundle */
    { name: 'library', path: '/workshop/:workshopId/files', component: LibraryPage,
        meta: {needsAuth: true, neededWsAction: 'a:list_files', title: 'Files'}},
     { name: 'outline', path: '/workshop/:workshopId/showcase/:showcaseId/outline', component: Outline,
        meta: {needsAuth: true, neededWsAction: 'a:list_pres_for_edit', title: 'Outline'},
        children: [{
            name: 'outline-preview', path: '/workshop/:workshopId/showcase/:showcaseId/preview',
            meta: {needsAuth: true, neededWsAction: 'a:list_pres_for_edit', title: 'Preview', scParentRouteName: 'outline'},
            component: BrowserViewerPresentation, props: { useDraft: true }}]
    },
    { name: 'email-viewers', path: '/workshop/:workshopId/showcase/:showcaseId/email-viewers', component: EmailViewers,
        meta: {needsAuth: true, neededWsAction: 'a:list_pres_for_edit', title: 'Email Viewers'}},

    /* viewer bundle */
    { name: 'viewer-list', path: '/workshop/:workshopId/showcases', component: BrowserViewerList,
        meta: {needsAuth: true, neededWsAction: 'a:list_pres_for_view', title: 'Showcases', scRemountKey: 'scviewer'},
        children: [{
            name: 'viewer-presentation', path: '/workshop/:workshopId/showcases/:showcaseId',
            meta: {needsAuth: true, neededWsAction: 'a:list_pres_for_view', title: 'Showcase',
                scRemountKey: 'scviewer', scParentRouteName: 'viewer-list'},
            component: BrowserViewerPresentation, props: { useDraft: false }}]},
    { name: 'search-all', path: '/workshop/:workshopId/search', component: SearchAllPage,
        meta: {needsAuth: true, neededWsAction: 'a:list_pres_for_view', title: 'Search'},
        children: [{
            name: 'search-all-viewer-presentation', path: '/workshop/:workshopId/showcases/:showcaseId',
            meta: {needsAuth: true, neededWsAction: 'a:list_pres_for_view', title: 'Showcase',
                scRemountKey: 'search-all-viewer', scParentRouteName: 'search-all'},
            component: BrowserViewerPresentation, props: { useDraft: false }}]},

    /* reporting bundle */
    { name: 'reporting-analytics', path: '/workshop/:workshopId/analytics', component: Analytics,
        meta: {needsAuth: true, neededWsAction: 'a:list_analytics', title: 'Analytics'}},
    { name: 'reporting-activity', path: '/workshop/:workshopId/activity', component: Activity,
        meta: {needsAuth: true, neededWsAction: 'a:list_own_activity', title: 'Activity'}},
    { name: 'reporting-sharing-history', path: '/workshop/:workshopId/sharing-history', component: SharingHistory,
        meta: {needsAuth: true, neededWsAction: 'a:list_own_sharing_hist', title: 'Sharing History'}},
    { name: 'reporting-forms', path: '/workshop/:workshopId/form', component: Form,
        meta: {needsAuth: true, neededWsAction: 'a:list_own_form_results', title: 'Form'}},

    /* shared files view bundle */
    { name: 'view-shared-files', path: '/share/:sharedKey', component: ViewShared,
        meta: {needsAuth: false, neededWsAction: null, title: 'View Shared Files'}},
    // legacy path (/share/:key is correct path)
    { name: 'view-shared-files-legacy', path: '/workshop/view-shared/:sharedKey', component: ViewShared,
        meta: {needsAuth: false, neededWsAction: null, title: 'View Shared Files'}},

    /* debug bundle */
    { name: 'debug-presentation', path: '/workshop/:workshopId/showcase/:showcaseId/debug', component: DebugPresentation,
        meta: {needsAuth: true, neededWsAction: 'a:list_pres_for_edit', title: 'Debug Presentation', scRemountKey: 'scdebug'}},
    { name: 'debug-file-usages', path: '/workshop/:workshopId/files/debug-usages', component: DebugFileUsages,
        meta: {needsAuth: true, neededWsAction: 'a:list_pres_for_edit', title: 'Debug File Usages'}},

];

const router = createRouter({
    history: createWebHistory('/'),
    routes: routes,
    scrollBehavior (to, from, savedPosition) {
        return new Promise((resolve) => {
            setTimeout(() => {  // crude method of restoring scroll position
                resolve(savedPosition ? savedPosition : { left: 0, top: 0 });
            }, 500);
        });
    }
});

router.afterEach((to) => {
    setTimeout(() => {
        //console.log('set title', to.meta.title);
        if (to.meta && to.meta.title) document.title = to.meta.title + ' - Showcase Workshop';
        else document.title = 'Showcase Workshop';

        ScViewTrackEvents.trackPageView(to);
    });
});


export default router;