
import ScResourceInfoModal from './global/ScResourceInfoModal.vue'
import ScCommonUtil from "../shared/common/ScCommonUtil";

export default {
    name: 'MainAppMixin',
    methods: {
        // should always start with mainMx

        mainMxThemedAssetUrl: function(path) {
            return 'https://cdn.showcaseworkshop.com/showcase-custom-themes/1.0.0/' + this.mainMxScLang + path;
        },
        mainMxCdn: function(path) {
            return 'https://cdn.showcaseworkshop.com' + path;
        },
        mainMxShowResInfo: function(id) {
            ScResourceInfoModal.modal({
                workshopId: this.$store.state.user.currentWorkshopId,
                resourceId: Number(id),
                canPerformListAnalytics: this.mainMxUwCanPerform('a:list_analytics'),
                canPerformListPresForView: this.mainMxUwCanPerform('a:list_pres_for_view')
            });
        },
        mainMxScDateFmt: function(dt) {
            return ScCommonUtil.scDateFmt(dt);
        },
        mainMxUwCanPerform: function(action) {
            return this.$store.getters.userCanPerform(action, this.mainMxCurrentWorkshopId);
        },
    },
    computed: {
        // should always start with mainMx
        mainMxCurrentWorkshop: function() {
            return this.$store.getters.userCurrentWorkspaceObj;
        },
        mainMxCurrentWorkshopId: function() {
            return this.$store.state.user.currentWorkshopId;
        },
        mainMxAppVer: function() {
            return window.SC_VERSION ? window.SC_VERSION : '0.0.1';
        },
        mainMxScLang: function() {
            return window.SC_LANG ? window.SC_LANG : 'en-en';
        },
    }
}