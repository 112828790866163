/**
 *
 * Common Utilities
 *
 *
 */

import {format, isSameDay, isSameYear} from 'date-fns';

/* private */
/* private */
/* private */
/* private */

let _evtFns = {};

let _clientIconName = {
  'default': 'tabletAltFW',
  'browser': 'browserFW',
  'apple_app': 'appleFW',
  'windows8_app': 'windowsFW',
  'windows_app': 'windowsFW',
  'windows_electron_app': 'windowsFW',
  'mac_electron_app': 'appleFW',
  'android_app': 'androidFW',
  'chrome_app': 'chromeFW'
};


/* public */
/* public */
/* public */
/* public */


let ScCommonUtil = {

  triggerEvent (evtName, evtObj) {
    //console.log('main.triggerEvent', evtName);
    let evt = new CustomEvent(evtName, {detail: evtObj});
    document.dispatchEvent(evt);
  },

  onEvent (evtName, onFn){
    //console.log('main.onEvent listen for', evtName);
    _evtFns[evtName] = function(e) {
      onFn(e && e.detail ? e.detail : null);
    };
    document.addEventListener(evtName, _evtFns[evtName]);
  },

  offEvent (evtName){
    //console.log('main.offEvent', evtName);
    document.removeEventListener(evtName, _evtFns[evtName]);
  },

  guid () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
      });
  },

  validateEmail (email) {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },

  httpCodeToText (httpStatus) {
    //console.log(httpStatus)
    if (httpStatus === 0) {
      return 'Offline or networking';
    } else if (httpStatus === 307) {
      return 'Proxy server redirect';
    } else if (httpStatus === 400) {
      return 'Bad request';
    } else if (httpStatus === 401) {
      return 'Authentication';
    } else if (httpStatus === 403) {
      return 'Unauthorised';
    } else if (httpStatus === 404) {
      return 'Not found';
    } else if (httpStatus === 429) {
      return 'Too many attempts (try again in one hour)';
    } else if (httpStatus === 500) {
      return 'Unexpected server error';
    } else {
      return httpStatus;
    }
  },

  parseIconFromClientName (clientName) {
    return (!clientName || !_clientIconName[clientName]) ? 'tabletAltFw' : _clientIconName[clientName];
  },

  scDateFmt (dt) {
    if (!dt) return null;
    let fmd = new Date(dt);
    if (isSameDay(fmd, new Date())) return format(fmd, 'h:mm a');
    if (isSameYear(fmd, new Date())) return format(fmd, 'd MMM, h:mm a');
    else return format(fmd, 'd MMM yyyy, h:mm a');
  },

  getThumbDimensionsFromUrl (url, defaultWidth, defaultHeight) {
    // get thumb dimensions from thumb name OR set defaults
    let dims = url.match(/([0-9]{3})x([0-9]{3})/);
    let height = (dims && dims.length > 0) ? Number(dims[2]) : defaultHeight;
    let width = (dims && dims.length > 0) ? Number(dims[1]) : defaultWidth;
    return {
      w: width,
      h: height
    }
  },
   filesizeWithBytes: function(bytes) {
        // based on https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
        if (!bytes) return '0';
        const k = 1024;
        const dm = 1;
        const sizes = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

};

// test
// [null, 0, 123, 1023, 1234, 12345, 123456, 1234567, 12345678, 123456789, 1234567890, 9999, 9999999999999].forEach((n) => {
//     console.log('example', ScCommonUtil.filesizeWithBytes(n));
// })


export default ScCommonUtil;
