<template>
    <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" v-vb-is:modal.show ref="scModal" @vb-shown-bs-modal="setFocus"
         @vb-hide-bs-modal="canModalHide" @vb-hidden-bs-modal="isModalShown=false"
         class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content shadow-lg">
                <form @submit.prevent target="#">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    <h4 class="modal-title w-100 text-center">Rename</h4>
                </div>
                <div class="modal-body pt-4">
                    <div class="input-group">
                        <div v-if="additionalInputPrepend" class="input-group-text">
                            {{additionalInputPrepend}}
                        </div>
                        <input type="text" :maxlength="maxLength" class="form-control"
                               v-model.trim="name" :disabled="savingRename"
                               @keyup.enter.prevent="returnValue"  ref="renameInput"/>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary-orange fw-bold"
                            @click.prevent="returnValue"
                            :disabled="savingRename">
                        <ScIcon v-if="savingRename" name="spinnerFW" class="mx-2" />
                        <span v-else>Save</span>
                    </button>
                </div>
                </form>
            </div>
        </div>
    </div>
    </teleport>
</template>


<script>

    import ScNotification from './ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';

    let _ins = null;

    export default {
        name: 'ScRenameModal2',
        components: {ScIcon},
        data () {
            return {
                isModalShown: false,
                newName: null,
                name: null,
                successAsyncFn: null,
                savingRename: false,
                maxLength: 2048,
                allowEmpty: false,
                additionalInputPrepend: null
            };
        },
        mounted () {
            _ins = this;
        },
        beforeUnmount() {
            _ins = null;
        },
        methods: {
            setFocus () {
                this.$refs.renameInput.focus();
            },
            canModalHide(e) {
                if (this.savingRename) e.preventDefault();
            },
            hideModal() {
                this.$refs.scModal.$vb.modal.hide();
            },
            returnValue () {
                if (this.maxLength && this.name && this.name.length > this.maxLength) {
                    ScNotification.growlErrMsg("The name can't be longer than " + this.maxLength + " characters");
                } else if (!this.allowEmpty && (!this.name || this.name.length === 0)) {
                    ScNotification.growlErrMsg('Please enter a name');
                }   else {
                    if (this.successAsyncFn) {
                        this.savingRename = true;
                        this.successAsyncFn(this.name).then(() => {
                            this.savingRename = false;
                            this.hideModal();
                        }, () => {
                            this.savingRename = false;
                        });
                    }
                }
            },

            toggleBsModal(options, successAsyncFn) {
                this.newName = null;
                this.name = options.name;
                this.maxLength = options.maxLength;
                this.additionalInputPrepend = options.additionalInputPrepend ? options.additionalInputPrepend : null;
                this.allowEmpty = !!options.allowEmpty;
                this.successAsyncFn = successAsyncFn;
                this.isModalShown = true;
            },
        },

        //
        // public functions
        //

        modal (options, successAsyncFn) {
            if (_ins) _ins.toggleBsModal(options, successAsyncFn);
        }


    }

</script>

